import React from "react";

const FormStep = ({ step, totalStep }: { step: number; totalStep: number }) => {
  return (
    <ul className="rolo-tab-list">
      {Array.from({ length: totalStep }, (_, i) => i + 1).map(
        (stepVal: number) => (
          <li key={stepVal} className={stepVal <= step ? `rolo-on-form` : ""}>
            <a href="#" className={stepVal === totalStep ? `rolo-done` : ""}>
              {stepVal !== totalStep ? stepVal : ""}
            </a>
          </li>
        )
      )}
    </ul>
  );
};

export default FormStep;
