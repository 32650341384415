import React, { useEffect, useMemo, useRef, useState } from "react";
import "./AssetManagement.css";
import check from "../../assets/check-circle.svg";
import "react-phone-number-input/style.css";
import FormStep from "./FormStep";
import { isValidPhoneNumber } from "react-phone-number-input";
import postContactDetails from "../../service/contactService";
import moment from "moment";
import { Link } from "react-router-dom";
import UserForm from "./UserForm";
import AssetForm from "./AssetForm";
import useIsInViewport from "./InviweObserver";

var emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AssetManagement = () => {
  const sectionRef = useRef<null | HTMLElement>(null);
  const sectionTopRef = useRef<null | HTMLElement>(null);
  const [assetStep, setAsserStep] = useState(1);
  const [isUserForm, setIsUserForm] = useState(false);
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<any>({
    appointment_time: "",
    time_zone: "",
  });
  const [validation, setValidation] = useState({});
  const isInViewport = useIsInViewport(sectionTopRef);

  const handleNext = () => {
    const { error, errorObj } = validate(isUserForm ? step : assetStep);

    if (error) {
      setValidation({
        ...validation,
        ...errorObj,
      });
    } else {
      if (isUserForm) {
        setStep((val) => val + 1);
      } else {
        setAsserStep((val) => val + 1);
      }
      if (sectionRef.current) {
        if (!isInViewport) {
          sectionRef.current.scrollIntoView();
        }
      }
    }
  };

  const handlePrevious = () => {
    if (isUserForm) {
      setStep((val) => val - 1);
    } else {
      setAsserStep((val) => val - 1);
    }
  };

  const handleSubmit = async () => {
    const { error, errorObj } = validate(step);

    if (error) {
      setValidation({
        ...validation,
        ...errorObj,
      });
    } else {
      const {
        company_size,
        location,
        appointment_time,
        appointment_date,
        details_other,
        time_zone,
        ...newFormDetails
      } = formData;

      newFormDetails[
        "application"
      ] = `Company size: ${company_size}\n, Location: ${location}\n, Appointment: ${moment(
        appointment_date
      ).format("DD/MM/YYYY")}, ${appointment_time} ${time_zone}`;
      newFormDetails["interest"] = newFormDetails["interest"].join("\n");

      if (newFormDetails["details"] && details_other) {
        const new_details = newFormDetails["details"].concat([details_other]);
        newFormDetails["details"] = new_details;
      } else if (details_other) {
        newFormDetails["details"] = [details_other];
      }
      newFormDetails["details"] = newFormDetails["details"].join("\n");
      setIsLoading(true);
      await postContactDetails(newFormDetails).then((data: any) => {
        setStep((val) => val + 1);
        setFormData({
          appointment_time: "",
          time_zone: "",
        });
      });
      setIsLoading(false);
    }
  };

  const validate = (step: number) => {
    let error = false;
    let errorObj: any = {};
    if (isUserForm) {
      if (step === 1) {
        if (!formData.company_size) {
          error = true;
          errorObj["company_size"] = "Please select an option";
        }
      } else if (step === 2) {
        if (!formData.location) {
          error = true;
          errorObj["location"] = "Please select location";
        }
      } else if (step === 3) {
        if (!formData.company) {
          error = true;
          errorObj["company"] = "Please enter name of your company";
        }
      } else if (step === 4) {
        if (!formData.first_name) {
          error = true;
          errorObj["first_name"] = "Please enter first name";
        }
        if (!formData.last_name) {
          error = true;
          errorObj["last_name"] = "Please enter last name";
        }
      } else if (step === 5) {
        if (!formData.email_id) {
          error = true;
          errorObj["email_id"] = "Please enter email id";
        } else if (!emailPattern.test(formData.email_id)) {
          error = true;
          errorObj["email_id"] = "Please enter valid email id";
        }

        if (!formData.phone_number) {
          error = true;
          errorObj["phone_number"] = "Please enter phone number";
        } else if (!isValidPhoneNumber(formData.phone_number)) {
          error = true;
          errorObj["phone_number"] = "Please enter valid phone number";
        }
      } else if (step === 6) {
        if (!formData.time_zone) {
          error = true;
          errorObj["time_zone"] = "Please select time zone";
        }

        if (!formData.appointment_date) {
          error = true;
          errorObj["appointment_date"] = "Please select appointment date";
        }

        if (!formData.appointment_time) {
          error = true;
          errorObj["appointment_time"] = "Please select appointment time";
        }
      }
    } else {
      if (step === 1) {
        if (!formData.interest || formData?.interest?.length === 0) {
          error = true;
          errorObj["interest"] = "Please select an option";
        }
      } else if (step === 2) {
        if (
          (!formData.details || formData?.details?.length === 0) &&
          !formData.details_other
        ) {
          error = true;
          errorObj["details"] = "Please select an option";
        }
      }
    }

    return { error, errorObj };
  };

  const validateNextBtn = useMemo(() => {
    let error = false;
    const stepcurrent = isUserForm ? step : assetStep;
    if (isUserForm) {
      if (stepcurrent === 1) {
        if (!formData.company_size) {
          error = true;
        }
      } else if (stepcurrent === 2) {
        if (!formData.location) {
          error = true;
        }
      } else if (stepcurrent === 3) {
        if (!formData.company) {
          error = true;
        }
      } else if (stepcurrent === 4) {
        if (!formData.first_name || !formData.last_name) {
          error = true;
        }
      } else if (stepcurrent === 5) {
        if (
          !formData.email_id ||
          !emailPattern.test(formData.email_id) ||
          !formData.phone_number ||
          !isValidPhoneNumber(formData.phone_number)
        ) {
          error = true;
        }
      } else if (stepcurrent === 6) {
        if (
          !formData.time_zone ||
          !formData.appointment_date ||
          !formData.appointment_time
        ) {
          error = true;
        }
      }
    } else {
      if (stepcurrent === 1) {
        if (!formData.interest || formData?.interest?.length === 0) {
          error = true;
        }
      } else if (stepcurrent === 2) {
        if (
          (!formData.details || formData?.details?.length === 0) &&
          !formData.details_other
        ) {
          error = true;
        }
      }
    }
    return error;
  }, [formData, step, assetStep]);

  const handleValueChange = (name: string, value: any) => {
    setValidation({
      ...validation,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSwitch = () => {
    setIsUserForm(true);
    setAsserStep(0);
    setStep(1);
  };

  return (
    <>
      <section className="rolo-asset-management-banner" ref={sectionTopRef}>
        <div className="rolo-container">
          <h2 className="rolo-banner-title">
            Let’s take a look at your asset management needs.
          </h2>
        </div>
      </section>
      <section className="rolo-asset-form" ref={sectionRef}>
        <div className="rolo-container ">
          {!isUserForm ? (
            <div className="rolo-top-two-step">
              <FormStep step={assetStep} totalStep={3} />
              <form>
                <AssetForm
                  step={assetStep}
                  formData={formData}
                  validation={validation}
                  handleValueChange={handleValueChange}
                />
                <div className="rolo-from-btns">
                  {assetStep !== 1 && (
                    <input
                      className="rolo-from-btn rolo-form-prv"
                      type="button"
                      value="Previous"
                      onClick={handlePrevious}
                    />
                  )}
                  <input
                    className={`rolo-from-btn ${
                      !validateNextBtn ? "rolo_btn_solid" : ""
                    }`}
                    type="button"
                    value="Next"
                    onClick={handleNext}
                  />
                </div>
              </form>
            </div>
          ) : (
            <div className="rolo-asset-container">
              <FormStep step={step} totalStep={7} />
              <form>
                <UserForm
                  step={step}
                  formData={formData}
                  validation={validation}
                  handleValueChange={handleValueChange}
                />
                <div className="rolo-from-btns">
                  {step != 1 && (
                    <input
                      className="rolo-from-btn rolo-form-prv"
                      type="button"
                      value="Previous"
                      onClick={handlePrevious}
                    />
                  )}
                  {step != 6 && (
                    <input
                      className={`rolo-from-btn ${
                        !validateNextBtn ? "rolo_btn_solid" : ""
                      }`}
                      type="button"
                      value="Next"
                      onClick={handleNext}
                    />
                  )}
                  {step === 6 && (
                    <input
                      className={`rolo-from-btn rolo-submit-btn${
                        !validateNextBtn ? " rolo_btn_solid" : ""
                      }${
                        isLoading ? " btn_loading" : ""
                      }`}
                      type="button"
                      value="Submit"
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      </section>

      {step === 7 && isUserForm && (
        <div className="rolo-thnkyou-popup">
          <div className="rolo-thank-you">
            <div className="thank-check">
              <img src={check} alt="check" />
            </div>
            <h4>Thank You!</h4>
            <p>One of our IIoT specialists will be reaching out to you soon.</p>
            <Link to="/" className="popup-home">
              Back to home
            </Link>
          </div>
        </div>
      )}

      {assetStep === 3 && !isUserForm && (
        <div className="rolo-thnkyou-popup great-popup">
          <div className="rolo-thank-you">
            <h4>Great!</h4>
            <p>
              At Rulo, we love solving problems. Now, we would like to
              understand your users better.
            </p>
            <Link to="#" className="popup-home" onClick={handleFormSwitch}>
              Next
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default AssetManagement;
