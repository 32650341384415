import CryptoAES from 'crypto-js/aes';

async function postContactDetails(data: any) {
    let key = CryptoAES.encrypt(process.env.REACT_APP_CONTACT_KEY as string , process.env.REACT_APP_CONTACT_PASSWORD as string);
    const response = await fetch(process.env.REACT_APP_CONTACT_URL as string, {
        method: 'POST',
        headers: {'Content-Type':'application/json', 'x-api-key' :process.env.REACT_APP_CONTACT_KEY as string, 'authorization' :key.toString()},
        body: JSON.stringify(data)
    });

    return await response.json();
}

export default postContactDetails