import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import "./Platform.css";
import rulo_matrix from "../../assets/rulo_matrix.svg";
import eye_icon from "../../assets/platform/eye_icon.svg";
import signal_icon from "../../assets/platform/signal_icon.svg";
import graph_icon from "../../assets/platform/graph_icon.svg";
import equipment_icon from "../../assets/platform/equipment_icon.svg";
import checkmark_icon from "../../assets/platform/checkmark_icon.svg";
import platform_works_icon from "../../assets/platform/platform_works_icon.svg";
import platform_works_icon2 from "../../assets/platform/platform_works_icon2.png";
import platform_works_icon2_mobile_view from "../../assets/platform/platform_works_icon2_mobile_view.svg";
import getDataFromS3 from "../../service/services";
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  EffectFlip
} from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname: string) => navigate(`${pathname}`);
};

const Platform = () => {
  const [hardware_data, setHardwareData] = useState<any[]>([]);
  const [feature_data, setFeatureData] = useState<any[]>([]);
  const [features_data_mobile, setFeaturesDataMobile] = useState<any[]>([]);
  const [mobilePlatformTileActive, setMobilePlatformTileActive] = useState<
    number
  >(0);
  const [mobileTilesSliderArr, setMobileTilesSliderArr] = useState<any[]>([
    {
      heading: "Operations",
      heading_orange: "made simple",
      sub_heading:
        "Monitor and automate your industrial equipment remotely and securely with the Rulo EdgeTrak controller.",
      product: "EdgeTrak 4S",
      price: "",
      subscription: "*Separate monthly subscription starting at $30/mo"
    },
    {
      heading: "Operations",
      heading_orange: "made simple",
      sub_heading:
        "Wirelessly connect additional devices to an EdgeTrak gateway and manage your assets.",
      product: "EdgeTrak 4N",
      price: "",
      subscription: "*Separate monthly subscription may apply"
    },
    {
      heading: "Coming",
      heading_orange: "soon!",
      sub_heading:
        "Monitor, automate and perform analytics on your industrial processes remotely and securely with the Rulo EdgeTrak controller.",
      product: "EdgeTrak 4L",
      price: "",
      subscription: "*Separate monthly subscription starting at $60/mo"
    },
    {
      heading: "Future",
      heading_orange: "product",
      sub_heading:
        "Wirelessly connect the EdgeTrak 4M methane sensors to an EdgeTrak gateway and track your asset emissions.",
      product: "EdgeTrak 4M",
      price: "",
      subscription: "*Separate monthly subscription starting at $30/mo"
    }
  ]);
  /* state variable products added to show the names of products on the line in the banner */
  const [products, setProductsActive] = useState<any>([
    { product_no: "01", name: "EdgeTrak 4S", is_active: true },
    { product_no: "02", name: "EdgeTrak 4N", is_active: false },
    { product_no: "03", name: "EdgeTrak 4L", is_active: false },
    { product_no: "04", name: "EdgeTrak 4M", is_active: false }
  ]);

  const [companyActive, setCompanyActive] = useState<number>(0);
  const [smallScreen, setSmallScreen] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState("01");
  /* Added state variable for showing the banner texts of particular product */
  const [platformBannerValues, setPlatformBannerValues] = useState<any>({
    heading: "Operations",
    heading_orange: "made simple",
    sub_heading:
      "Monitor and automate your industrial equipment remotely and securely with the Rulo EdgeTrak controller.",
    product: "EdgeTrak 4S",
    price: "",
    subscription: "*Separate monthly subscription starting at $30/mo"
  });

  useEffect(() => {
    document.title = 'Monitor, Control and Manage | Rulo';
  }, []);

  useEffect(() => {
    if (window.innerWidth > 850) {
      setSmallScreen(false);
    } else {
      setSmallScreen(true);
    }
  });
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 850) {
        setSmallScreen(false);
      } else {
        setSmallScreen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const navigateSearch = useNavigateSearch();
  /* for navigating to particular page */
  const navTo = (path: string) => {
    window.scrollTo(0, 0);
    navigateSearch(path);
  };

  /* Updating the banner text according to the product selected */
  const changePlatformTab = (index: any, product_no: any) => {
    let products_copy = Object.assign([], products);
    products_copy[index].is_active = true;
    products_copy.forEach((element: any, index1: any) => {
      if (index1 != index) {
        element.is_active = false;
      }
    });
    setProductsActive(products_copy);
    setActiveTab(product_no);
    if (product_no === "02") {
      setPlatformBannerValues({
        heading: "Operations",
        heading_orange: "made simple",
        sub_heading:
          "Wirelessly connect additional devices to an EdgeTrak gateway and manage your assets.",
        product: "EdgeTrak 4N",
        price: "",
        subscription: "*Separate monthly subscription may apply"
      });
    } else if (product_no === "03") {
      setPlatformBannerValues({
        heading: "Coming",
        heading_orange: "soon!",
        sub_heading:
          "Monitor, automate and perform analytics on your industrial processes remotely and securely with the Rulo EdgeTrak controller.",
        product: "EdgeTrak 4L",
        price: "",
        subscription: "*Separate monthly subscription starting at $60/mo"
      });
    } else if (product_no === "04") {
      setPlatformBannerValues({
        heading: "Future",
        heading_orange: "product",
        sub_heading:
          "Wirelessly connect the EdgeTrak 4M methane sensors to an EdgeTrak gateway and track your asset emissions.",
        product: "EdgeTrak 4M",
        price: "",
        subscription: "*Separate monthly subscription starting at $30/mo"
      });
    } else {
      setPlatformBannerValues({
        heading: "Operations",
        heading_orange: "made simple",
        sub_heading:
          "Monitor and automate your industrial equipment remotely and securely with the Rulo EdgeTrak controller.",
        product: "EdgeTrak 4S",
        price: "",
        subscription: "*Separate monthly subscription starting at $30/mo"
      });
    }
  };

  /* redirecting to the product features div */
  const redirectToProducts = () => {
    let element = document.getElementById("product-features");
    let offsetTop = element?.getBoundingClientRect();
    window.scrollTo({
      top: Number(offsetTop?.top),
      behavior: "smooth"
    });
  };

  function onClickHomeCompanyDot(item: any) {
    let myCompanySlider = document.getElementById("home_company_slider");
    (myCompanySlider as any).swiper.slideTo(item, 500, false);
  }

  useEffect(() => {
    getDataFromS3("platform/product_feature.json").then((data: any) => {
      for (let hardware of data["hardware"]) {
        for (let temp_features of data["features"]) {
          for (let key of Object.keys(temp_features)) {
            if (key == hardware.id && hardware.visibility === "disable") {
              temp_features["not_visible"] = hardware.id;
            }
          }
        }
      }
      setHardwareData(data["hardware"]);
      setFeatureData(data["features"]);

      let features_data_temp_mobile = data["features"].slice(0, 3);
      setFeaturesDataMobile(features_data_temp_mobile);
    });
  }, []);

  return (
    <div>
      {/* Platform banner */}
      <Grid className="background">
        <div className="platform_banner_grid">
          {/* Platform main heading and subheading on the banner */}
          <div className="platform-banner-grid-item-1 platform_flex_column justify-content-end">
            <label className="platform-title">
              {platformBannerValues.heading}{" "}
              <span>{platformBannerValues.heading_orange}</span>
            </label>
            <p>{platformBannerValues.sub_heading}</p>
          </div>

          {/* platform images on the banner */}
          <div className="platform-banner-grid-item-2">
            {/* image urls to be updated for product_no 02(Edgetrak 4N) */
            activeTab === "02" ? (
              <div
                style={{ position: "relative", isolation: "isolate" }}
                className="platform_image_transform"
              >
                <img
                  className="platform_banner_ipad"
                  src={require("../../assets/platform/trend.png")}
                ></img>
                <img
                  className="platform_banner_iphone"
                  src={require("../../assets/platform/mobile.png")}
                ></img>

                <img
                  className="platform_banner_4s custom_banner_4n"
                  src={require("../../assets/platform/platform_banner_4n.png")}
                ></img>
              </div>
            ) : activeTab === "03" ? (
              <div
                style={{ position: "relative", isolation: "isolate" }}
                className="platform_image_transform"
              >
                <img
                  className="platform_banner_ipad"
                  src={require("../../assets/platform/home.png")}
                ></img>
                <img
                  className="platform_banner_iphone"
                  src={require("../../assets/platform/mobile.png")}
                ></img>

                <img
                  className="platform_banner_4s custom_banner_4l"
                  src={require("../../assets/platform/platform_banner_4l.png")}
                ></img>
              </div>
            ) : activeTab === "04" ? (
              <div
                style={{ position: "relative", isolation: "isolate" }}
                className="platform_image_transform"
              >
                <img
                  className="platform_banner_ipad"
                  src={require("../../assets/platform/trend.png")}
                ></img>
                <img
                  className="platform_banner_iphone"
                  src={require("../../assets/platform/mobile.png")}
                ></img>

                <img
                  className="platform_banner_4s custom_banner_4m"
                  src={require("../../assets/platform/platform_banner_4m.png")}
                ></img>
              </div>
            ) : (
              <div
                style={{ position: "relative", isolation: "isolate" }}
                className="platform_image_transform"
              >
                <img
                  className="platform_banner_ipad"
                  src={require("../../assets/platform/home.png")}
                ></img>
                <img
                  className="platform_banner_iphone"
                  src={require("../../assets/platform/mobile.png")}
                ></img>

                <img
                  className="platform_banner_4s"
                  src={require("../../assets/platform/platform_banner_4s.png")}
                ></img>
              </div>
            )}
          </div>

          {/* platform explor and learn more buttons, subscription and pricing text shown on the banner */}
          <div className="platform-banner-grid-item-3 platform_flex_column">
            <div>
              <p>
                <span>{platformBannerValues.product}</span>
              </p>
            </div>
            <div>
              <div className="btn-padding">
                <button
                  className="orange-button platform-banner-button-pd"
                  onClick={() => redirectToProducts()}
                >
                  <div
                    className="flex_row justify-content-center"
                    style={{ gap: "0.5em" }}
                  >
                    <div>
                      <img
                        style={{ width: "1rem" }}
                        src={require("../../assets/button_arrow.svg").default}
                      ></img>
                    </div>
                    <div style={{ height: "fit-content", color: "white" }}>
                      Explore options
                    </div>
                  </div>
                </button>
                <button
                  className="transparent-orangeBrder-button hide-mobile"
                  onClick={() => {
                    navTo("/contact");
                  }}
                >
                  <div
                    className="flex_row justify-content-center"
                    style={{ gap: "0.5em" }}
                  >
                    <div style={{ height: "fit-content" }}>Request info</div>
                  </div>
                </button>
              </div>
              <div className="platform-banner-button-text-below">
                {platformBannerValues.subscription}
              </div>
            </div>
          </div>
        </div>
        {/* div added for platform slider in mobile view */}
        <div className="platform_mobile_view">
          <Swiper
            id="mySwiperPlatform"
            modules={[Autoplay, Pagination, Scrollbar]}
            spaceBetween={50}
            slidesPerView={"auto"}
            onSlideChange={e => {
              setMobilePlatformTileActive(e.activeIndex);
            }}
          >
            {mobileTilesSliderArr.map((item: any, index: number) => {
              return (
                <SwiperSlide>
                  {/* Platform main heading and subheading on the banner */}
                  <div className="platform-banner-grid-item-1 platform_flex_column justify-content-end">
                    <label className="platform-title">
                      {item.heading} <span>{item.heading_orange}</span>
                    </label>
                    <p>{item.sub_heading}</p>
                  </div>

                  {/* platform images on the banner */}
                  <div className="platform-banner-grid-item-2">
                    {/* image urls to be updated for product_no 02(Edgetrak 4N) */
                    index === 1 ? (
                      <div
                        style={{ position: "relative", isolation: "isolate" }}
                        className="platform_image_transform"
                      >
                        <img
                          className="platform_banner_ipad"
                          src={require("../../assets/platform/trend.png")}
                        ></img>
                        <img
                          className="platform_banner_iphone"
                          src={require("../../assets/platform/mobile.png")}
                        ></img>

                        <img
                          className="platform_banner_4s custom_banner_4n"
                          src={require("../../assets/platform/platform_banner_4n.png")}
                        ></img>
                      </div>
                    ) : index === 2 ? (
                      <div
                        style={{ position: "relative", isolation: "isolate" }}
                        className="platform_image_transform"
                      >
                        <img
                          className="platform_banner_ipad"
                          src={require("../../assets/platform/home.png")}
                        ></img>
                        <img
                          className="platform_banner_iphone"
                          src={require("../../assets/platform/mobile.png")}
                        ></img>

                        <img
                          className="platform_banner_4s custom_banner_4l"
                          src={require("../../assets/platform/platform_banner_4l.png")}
                        ></img>
                      </div>
                    ) : index === 3 ? (
                      <div
                        style={{ position: "relative", isolation: "isolate" }}
                        className="platform_image_transform"
                      >
                        <img
                          className="platform_banner_ipad"
                          src={require("../../assets/platform/trend.png")}
                        ></img>
                        <img
                          className="platform_banner_iphone"
                          src={require("../../assets/platform/mobile.png")}
                        ></img>

                        <img
                          className="platform_banner_4s custom_banner_4m"
                          src={require("../../assets/platform/platform_banner_4m.png")}
                        ></img>
                      </div>
                    ) : (
                      <div
                        style={{ position: "relative", isolation: "isolate" }}
                        className="platform_image_transform"
                      >
                        <img
                          className="platform_banner_ipad"
                          src={require("../../assets/platform/home.png")}
                        ></img>
                        <img
                          className="platform_banner_iphone"
                          src={require("../../assets/platform/mobile.png")}
                        ></img>

                        <img
                          className="platform_banner_4s custom_banner_4s"
                          src={require("../../assets/platform/platform_banner_4s.png")}
                        ></img>
                      </div>
                    )}
                  </div>

                  {/* platform explor and learn more buttons, subscription and pricing text shown on the banner */}
                  <div className="platform-banner-grid-item-3 platform_flex_column">
                    <div>
                      <p>
                        <span>{item.product}</span>
                      </p>
                    </div>
                    <div>
                      <div className="btn-padding">
                        <button
                          className="orange-button platform-banner-button-pd"
                          onClick={() => redirectToProducts()}
                        >
                          <div
                            className="flex_row justify-content-center"
                            style={{ gap: "0.5em" }}
                          >
                            <div>
                              <img
                                style={{ width: "1rem" }}
                                src={
                                  require("../../assets/button_arrow.svg")
                                    .default
                                }
                              ></img>
                            </div>
                            <div
                              style={{ height: "fit-content", color: "white" }}
                            >
                              Explore options
                            </div>
                          </div>
                        </button>
                        <button
                          className="transparent-orangeBrder-button hide-mobile"
                          onClick={() => {
                            navTo("/contact");
                          }}
                        >
                          <div
                            className="flex_row justify-content-center"
                            style={{ gap: "0.5em" }}
                          >
                            <div style={{ height: "fit-content" }}>
                              Request info
                            </div>
                          </div>
                        </button>
                      </div>
                      <div className="platform-banner-button-text-below">
                        {item.subscription}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4,1fr)",
              width: "100%",
              paddingBottom: "1.375rem"
            }}
          >
            {[0, 1, 2, 3].map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  style={{
                    borderTop:
                      mobilePlatformTileActive == item
                        ? "3px solid #ff6900"
                        : "3px solid rgba(91, 103, 112,0.5)"
                  }}
                ></div>
              );
            })}
          </div>
        </div>

        {/* Platform tabs on banner */}
        <div className="platform_tabs">
          {products.map((product: any, index: number) => {
            return (
              <div
                className={product.is_active == true ? "active-tab" : ""}
                onClick={() => {
                  changePlatformTab(index, product.product_no);
                }}
              >
                <div className="platform-tab-index">{product.product_no}</div>
                <div className="platform-tab-names">{product.name}</div>
              </div>
            );
          })}
        </div>
      </Grid>

      {/* System Overview */}
      <Grid style={{ paddingRight: "8.3vw" }}>
        <div className="left_section_platform">
          <div className="pl-28per-platform">
            <div style={{ display: "flex" }}>
              <img src={rulo_matrix}></img>
              <label className="heading_text">System overview</label>
            </div>
          </div>
        </div>
        <div className="flex_display">
          <div className="center_section">
            <span className="center_text">Industry-leading, </span>
            <span className="center_text" style={{ color: "#FF6900" }}>
              fully integrated{" "}
            </span>
            <span className="center_text text_color_blue">
              {" "}
              asset management platform
            </span>
          </div>
          <div className="right_section_platform">
            {/* Site Visibility */}
            <div>
              <img src={eye_icon}></img>
              <div
                className="right_text_heading"
                style={{ marginTop: "0.7em" }}
              >
                Site visibility
              </div>
              <div className="right_sub_text">
                Securely manage your assets with Rulo’s fully-integrated and
                simplified solution, for less.
              </div>
            </div>

            {/* Real time data collection */}
            <div className="mq-mt-3em">
              <img src={graph_icon}></img>
              <div className="right_text_heading">Reliable data</div>
              <div className="right_sub_text">
                EdgeTrak controllers store data on a flash drive before
                forwarding to the cloud. This ensures data is not lost due to
                poor network conditions or power loss.
              </div>
            </div>

            {/* Easy to read UI */}
            <div className="mt-3em">
              <img src={signal_icon}></img>
              <div className="right_text_heading">Easy-to-read UI</div>
              <div className="right_sub_text">
                Using simple user-configurable event settings, manage the status
                of your equipment using a simple and intuitive UI.
              </div>
            </div>

            {/* Equipment integration */}
            <div className="mt-3em">
              <img src={equipment_icon}></img>
              <div className="right_text_heading">Equipment control</div>
              <div className="right_sub_text">
                Start or stop equipment remotely. Change operation modes. If it
                breaks, leaks, loses power, or keeps you up at night—we can help
                you manage it.
              </div>
            </div>
          </div>
        </div>
      </Grid>

      {/* How it works */}
      <Grid className="works_background">
        <div className="left_section_platform">
          <div className="pl-28per-platform">
            <div style={{ display: "flex" }}>
              <img src={rulo_matrix}></img>
              <label className="heading_text">How it works</label>
            </div>
          </div>
        </div>
        <div className="home_right_side">
          <Swiper
            id="home_company_slider"
            modules={[
              Navigation,
              Autoplay,
              Pagination,
              Scrollbar,
              A11y,
              EffectFade,
              EffectFlip
            ]}
            onSwiper={() => {}}
            spaceBetween={50}
            slidesPerView={"auto"}
            autoplay={{ delay: 5000 }}
            onSlideChange={e => {
              setCompanyActive(e.activeIndex);
            }}
          >
            <SwiperSlide>
              <div className="flex_display flex_display_xs">
                <div className="center_section_works">
                  <div className="center_text text_color_blue">Connect</div>
                  <div className="center_text" style={{ color: "#939393" }}>
                    {" "}
                    and
                  </div>
                  <div className="center_text" style={{ color: "#FF6900" }}>
                    {" "}
                    Configure
                  </div>
                  <div className="right_sub_text_monitor">
                    Register your device, connect your equipment and configure
                    remotely.
                  </div>
                </div>
                <div className="right_section_platform_footer_works">
                  <img
                    src={
                      smallScreen
                        ? platform_works_icon2_mobile_view
                        : platform_works_icon2
                    }
                    className="img_width"
                  ></img>
                </div>
              </div>
              <div className="center_section_footer_works">
                Rulo controllers have flexible user-configurable inputs and
                outputs that quickly enable connection to equipment and systems.
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex_display flex_display_xs">
                {/* How it works texts */}
                <div className="center_section_works">
                  <div className="center_text text_color_blue">
                    Monitor, control
                  </div>
                  <div className="center_text" style={{ color: "#939393" }}>
                    {" "}
                    and
                  </div>
                  <div className="center_text" style={{ color: "#FF6900" }}>
                    {" "}
                    manage
                  </div>
                  <div className="right_sub_text_monitor">
                    Through realtime analytics and easy-to-read dashboards and
                    reports, Rulo gives you everything you need to make key
                    decisions on the fly.
                  </div>
                </div>
                {/* image for how it works */}
                <div className="right_section_platform_works">
                  <img src={platform_works_icon} className="img_width"></img>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          <div className="platform_works_dot_tabs">
            {[0, 1].map((item: any) => (
              <div
                key={item}
                onClick={() => {
                  onClickHomeCompanyDot(item);
                }}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={
                    companyActive == item
                      ? `${
                          require("../../assets/Home/active_dot_tab.svg")
                            .default
                        }`
                      : `${
                          require("../../assets/Home/in_active_dot_tab.svg")
                            .default
                        }`
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </Grid>

      {/* Product Features */}
      <Grid id="product-features">
        <div className="left_section_platform">
          <div className="pl-28per-platform">
            <div style={{ display: "flex" }}>
              <img src={rulo_matrix}></img>
              <label className="heading_text">Product features</label>
            </div>
          </div>
        </div>
        {/* product feature table for the desktop/laptop screens */}
        <div className="right_section_table">
          <div className="table_outlines">
            <table>
              <thead>
                <tr>
                  <th style={{ background: "white" }}></th>
                  {hardware_data.map((data: any, index: number) => {
                    return (
                      <th key={index} style={{ verticalAlign: "top" }}>
                        <div className="platform_flex_column justify-content-center">
                          <div
                            className={
                              data.visibility == "active"
                                ? "table_heading"
                                : "table_heading disable_txt"
                            }
                          >
                            {data.heading}
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <div
                              className={
                                data.visibility == "active"
                                  ? "sub_heading"
                                  : "sub_heading disable_txt"
                              }
                            >
                              {data.sub_heading}
                            </div>
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {feature_data.map((features: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      className={
                        index % 2 == 0 ? "table_row_even" : "table_row_odd"
                      }
                    >
                      <th className="table_feature">{features.feature_name}</th>
                      {hardware_data.map((hardware: any, index: number) => {
                        return (
                          <td
                            className={
                              features.not_visible == hardware.id
                                ? "table_feature align_center disable_txt"
                                : "table_feature align_center"
                            }
                          >
                            {features[hardware.id] == "true" ? (
                              <img
                                src={checkmark_icon}
                                className={
                                  features.not_visible == hardware.id
                                    ? "disable_btn"
                                    : ""
                                }
                              ></img>
                            ) : (
                              features[hardware.id]
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr>
                  <th style={{ background: "white" }}></th>
                  {hardware_data.map((data: any, index: number) => {
                    return (
                      <td key={index}>
                        <div className="align_center">
                          {data.product_link === "" ||
                          data.visibility !== "active" ? (
                            <></>
                            // <button className="product_sheet_btn disable_btn">
                            //   <span className="product_sheet_btn_txt">
                            //     Product sheet
                            //   </span>
                            // </button>
                          ) : (
                            <a href={data.product_link} target="_blank">
                              <button className="product_sheet_btn">
                                <span className="product_sheet_btn_txt">
                                  Product sheet
                                </span>
                              </button>
                            </a>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* product features items listed with checkboxs for the mobile/tablet screens */}
        <div className="features_data_mobile">
          {hardware_data.map((data: any) => {
            return (
              <div>
                <div className="features_heading">{data.heading}</div>
                <div className="features_sub_heading">{data.sub_heading}</div>
                <div className="features_includes">Includes:</div>
                {features_data_mobile.map((features: any, index: number) => {
                  return (
                    <div className="features_checkbox">
                      {features[data.id] !== "" ? (
                        <span>
                          <input
                            type="checkbox"
                            id={index.toString()}
                            name={features.feature_name}
                            checked
                          />
                          <span className="features_list">
                            {features.feature_name}{" "}
                            {features[data.id] !== "true" ? (
                              <span>- {features[data.id]}</span>
                            ) : (
                              ""
                            )}
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
                {/* Product sheet button for mobile screen */}
                {data.product_link === "" || data.visibility !== "active" ? (
                  <button className="product_sheet_btn platform-banner-button-pd ml-4per disable_btn">
                    <div
                      className="flex_row justify-content-center"
                      style={{ gap: "0.5em" }}
                    >
                      <div
                        style={{ height: "fit-content" }}
                        className="product_sheet_btn_txt margin_0"
                      >
                        Product sheet
                      </div>
                    </div>
                  </button>
                ) : (
                  <a href={data.product_link} target="_blank">
                    <button className="product_sheet_btn platform-banner-button-pd ml-4per">
                      <div
                        className="flex_row justify-content-center"
                        style={{ gap: "0.5em" }}
                      >
                        <div
                          style={{ height: "fit-content" }}
                          className="product_sheet_btn_txt margin_0"
                        >
                          Product sheet
                        </div>
                      </div>
                    </button>
                  </a>
                )}
              </div>
            );
          })}
        </div>
      </Grid>
    </div>
  );
};
export default Platform;
