import React, { useState, useCallback, useEffect } from "react";
import getDataFromS3 from "../../service/services";
import { Grid } from "@material-ui/core";
import "./Industry.css";
import button_arrow from "../../assets/button_arrow.svg";
import close_icon from "../../assets/Industry/close_icon.svg";
import arrow_icon from "../../assets/Industry/arrow_icon.svg";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import oil_gas_img from "../../assets/Industry/oil_gas_desktop.png";
import crypto_mining_img from "../../assets/Industry/crypto_mining_desktop.png";
import municipality_img from "../../assets/Industry/municipality_desktop.png";
import rental_asset_tracking_img from "../../assets/Industry/rental_asset_tracking_desktop.png";
import construction_img from "../../assets/Industry/construction_desktop.png";
import industrial_manufacturing_img from "../../assets/Industry/industrial_manufacturing_desktop.png";
import telecommunications_img from "../../assets/Industry/telecommunications_desktop.png";
import emmisions_management_img from "../../assets/Industry/emmisions_management_desktop.png";
import emergency_relief_img from "../../assets/Industry/emergency_relief_desktop.png";

const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname: string) => navigate(`${pathname}`);
};

const Industry = () => {
  const [ismobileview, setIsmobileview] = useState(false);
  const [isTileClicked, setIsTileClicked] = useState(false);
  const [industryData, setIndustryData] = useState<any>([]);
  const [usecaseData, setUsecaseData] = useState<any>([]);
  const [toggleValue, setToggleValue] = useState("");

  const getData = (clickedTile: string) => {
    setToggleValue(clickedTile);
    setIsTileClicked(true);
    var path = "industry/" + clickedTile + "/industry.json";

    getDataFromS3(path).then((data: any) => {
      switch (clickedTile) {
        case "oil_gas":
          data["image"] = oil_gas_img;
          break;
        case "crypto_mining":
          data["image"] = crypto_mining_img;
          break;
        case "municipality":
          data["image"] = municipality_img;
          break;
        case "rental_asset_tracking":
          data["image"] = rental_asset_tracking_img;
          break;
        case "construction":
          data["image"] = construction_img;
          break;
        case "industrial_manufacturing":
          data["image"] = industrial_manufacturing_img;
          break;
        case "emmisions_management":
          data["image"] = emmisions_management_img;
          break;
        case "telecommunications":
          data["image"] = telecommunications_img;
          break;
      }
      setIndustryData(data);
      setUsecaseData(data["usecases"]);
      if (window.innerWidth < 850) {
        var doc1 = document.getElementById(clickedTile) as HTMLElement | null;
        doc1?.scrollIntoView();
      }
    });
  };

  const navigateSearch = useNavigateSearch();
  const navTo = (path: string) => {
    window.scrollTo(0, 0);
    navigateSearch(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.innerWidth <= 850) {
      setIsmobileview(true);
    } else {
      setIsmobileview(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    document.title = "Industries of Focus | Rulo";
  }, []);

  return (
    <Grid>
      <Grid className="industry_banner">
        <div className="banner_text_industry">
          Uniquely positioned{" "}
          <span className="industrial_text">to serve you</span>
        </div>
        <div className="banner_text1_industry">
          The RULO asset management suite empowers you to take full control of
          your industrial equipment through information and customizable
          automation that you can access anytime, anywhere on any device.
        </div>
      </Grid>
      <Grid>
        <section style={{ background: "#5B6770" }}>
          <div className="industry_flex_row">
            <div className="industry_left_section ">
              <div className=" left_section_industry">
                <div className="d-flex align-items-center">
                  <img
                    className="left_dots"
                    src={require("../../assets/rulo_matrix.svg").default}
                  ></img>
                  <label className="industry_left_sec_header">
                    Industries of focus
                  </label>
                </div>
                <div className="all_industry_tiles_mobile">
                  <div
                    className="industry_right_side_mobile industry_parent_mobile "
                    style={{ position: "relative" }}
                  >
                    {/* {isTileClicked && (
                      <div className="industry_tile_clicked_div">
                        <div className="image_div_tile_clicked">
                          <img src={industryData?.image} alt="" />
                        </div>
                        <div className="description_industry_tile">
                          <div className="description_industry_tile_title">
                            {industryData?.industry}
                          </div>
                          <div className="descriptive_text">
                            {industryData?.description}
                          </div>
                        </div>
                        <div className="usecase_div">
                          <div className="usecases">
                            <div className="usecase_heading">Use Cases</div>
                            {usecaseData.map((industry: any, index: number) => {
                              return (
                                <div className="usecase_options" key={index}>
                                  {industry.name}
                                </div>
                              );
                            })}
                          </div> */}
                    {/* <div className="usecase_image_div">
                            <img src={industryData?.image} alt="" />
                            <div className="orange_div">
                              <div className="rulo_text_industry">
                                {industryData?.get_details?.brief_desc}
                              </div>
                              <div className="casestudy_text">
                                {industryData?.get_details?.supporting_text}
                              </div>
                              <div>
                                <img className="arrow_icon" src={arrow_icon} />{" "}
                              </div>
                            </div>
                          </div> */}
                    {/* </div>
                      </div>
                    )} */}
                    <div
                      className="full_industry_tiles_mobile"
                      id="industry_tiles"
                    >
                      <Accordion allowZeroExpanded>
                        <AccordionItem id="oil_gas">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {toggleValue !== "oil_gas" ? (
                                <div
                                  className="industry_tile_mobile"
                                  style={{
                                    backgroundImage: `url(${require("../../assets/Home/oil_industry_tile_bg.png")})`
                                  }}
                                  onClick={() => {
                                    getData("oil_gas");
                                  }}
                                >
                                  <img
                                    className="img_icon"
                                    src={
                                      require("../../assets/Home/oil-pump_logo.svg")
                                        .default
                                    }
                                  ></img>
                                  <div className="industry_tile_title_mobile">
                                    Oil / gas
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="image_div_tile_clicked_mobile"
                                  onClick={() => {
                                    setToggleValue("");
                                  }}
                                >
                                  <img
                                    src={industryData?.image}
                                    className="image_mobile_view"
                                    alt=""
                                  />
                                </div>
                              )}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div>
                              <div className="industry_tile_clicked_div_mobile">
                                <div className="description_industry_tile_mobile">
                                  <div className="desc_icon_div_mobile">
                                    <img
                                      style={{ color: "white" }}
                                      src={industryData?.desc_icon}
                                    />
                                  </div>
                                  <div className="description_industry_tile_title_mobile">
                                    {industryData?.industry}
                                  </div>
                                  <div
                                    className="descriptive_text_mobile"
                                    dangerouslySetInnerHTML={{
                                      __html: industryData?.description
                                    }}
                                  ></div>
                                </div>
                                <div className="usecase_div_mobile">
                                  <div className="usecase_heading_mobile">
                                    Use Cases
                                  </div>
                                  {usecaseData.map(
                                    (industry: any, index: number) => {
                                      return (
                                        <div
                                          className="usecase_options_mobile"
                                          key={index}
                                        >
                                          {industry.name}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {industryData?.get_details?.pdf_link != "" ? (
                                  <div className="orange_div_mobile">
                                    <div className="rulo_text_industry_mobile">
                                      {industryData?.get_details?.brief_desc}
                                    </div>
                                    <div className="div_containing_clickable_text_mobile">
                                      <img
                                        className="arrow_icon_mobile"
                                        src={arrow_icon}
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      />{" "}
                                      <span
                                        className="orange_div_text_mobile"
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        Download case study
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        {/* Commented out the crypto mining tile */}
                        {/* <AccordionItem id="crypto_mining">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {toggleValue !== "crypto_mining" ? (
                                <div
                                  className="industry_tile_mobile"
                                  style={{
                                    backgroundImage: `url(${require("../../assets/Industry/crypto_mining.png")})`
                                  }}
                                  onClick={() => {
                                    getData("crypto_mining");
                                  }}
                                >
                                  <img
                                    className="img_icon"
                                    src={
                                      require("../../assets/Home/crypto_logo.svg")
                                        .default
                                    }
                                  ></img>
                                  <div className="industry_tile_title_mobile">
                                    Crypto Mining
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="image_div_tile_clicked_mobile"
                                  onClick={() => {
                                    setToggleValue("");
                                  }}
                                >
                                  <img
                                    src={industryData?.image}
                                    className="image_mobile_view"
                                    alt=""
                                  />
                                </div>
                              )}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div>
                              <div className="industry_tile_clicked_div_mobile">
                                <div className="description_industry_tile_mobile">
                                  <div className="desc_icon_div_mobile">
                                    <img
                                      style={{ color: "white" }}
                                      src={industryData?.desc_icon}
                                    />
                                  </div>
                                  <div className="description_industry_tile_title_mobile">
                                    {industryData?.industry}
                                  </div>
                                  <div
                                    className="descriptive_text_mobile"
                                    dangerouslySetInnerHTML={{
                                      __html: industryData?.description
                                    }}
                                  ></div>
                                </div>
                                <div className="usecase_div_mobile">
                                  <div className="usecase_heading_mobile">
                                    Use Cases
                                  </div>
                                  {usecaseData.map(
                                    (industry: any, index: number) => {
                                      return (
                                        <div
                                          className="usecase_options_mobile"
                                          key={index}
                                        >
                                          {industry.name}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {industryData?.get_details?.pdf_link!=""?
                                <div className="orange_div_mobile">
                                  <div className="rulo_text_industry_mobile">
                                    {industryData?.get_details?.brief_desc}
                                  </div>
                                  <div className="div_containing_clickable_text_mobile">
                                    <img
                                      className="arrow_icon_mobile"
                                      src={arrow_icon}
                                      onClick={() => {
                                        window.open(
                                          industryData?.get_details?.pdf_link,
                                          "_blank"
                                        );
                                      }}
                                    />{" "}
                                    <span
                                      className="orange_div_text_mobile"
                                      onClick={() => {
                                        window.open(
                                          industryData?.get_details?.pdf_link,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      Download case study
                                    </span>
                                  </div>
                                </div>
                                :<></>}
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem> */}
                        <AccordionItem id="municipality">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {toggleValue !== "municipality" ? (
                                <div
                                  className="industry_tile_mobile"
                                  style={{
                                    backgroundImage: `url(${require("../../assets/Industry/municipality.png")})`
                                  }}
                                  onClick={() => {
                                    getData("municipality");
                                  }}
                                >
                                  <img
                                    className="img_icon"
                                    src={
                                      require("../../assets/Home/muni_logo.svg")
                                        .default
                                    }
                                  ></img>
                                  <div className="industry_tile_title_mobile">
                                    Municipality
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="image_div_tile_clicked_mobile"
                                  onClick={() => {
                                    setToggleValue("");
                                  }}
                                >
                                  <img
                                    src={industryData?.image}
                                    className="image_mobile_view"
                                    alt=""
                                  />
                                </div>
                              )}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div>
                              <div className="industry_tile_clicked_div_mobile">
                                <div className="description_industry_tile_mobile">
                                  <div className="desc_icon_div_mobile">
                                    <img
                                      style={{ color: "white" }}
                                      src={industryData?.desc_icon}
                                    />
                                  </div>
                                  <div className="description_industry_tile_title_mobile">
                                    {industryData?.industry}
                                  </div>
                                  <div
                                    className="descriptive_text_mobile"
                                    dangerouslySetInnerHTML={{
                                      __html: industryData?.description
                                    }}
                                  ></div>
                                </div>
                                <div className="usecase_div_mobile">
                                  <div className="usecase_heading_mobile">
                                    Use Cases
                                  </div>
                                  {usecaseData.map(
                                    (industry: any, index: number) => {
                                      return (
                                        <div
                                          className="usecase_options_mobile"
                                          key={index}
                                        >
                                          {industry.name}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {industryData?.get_details?.pdf_link != "" ? (
                                  <div className="orange_div_mobile">
                                    <div className="rulo_text_industry_mobile">
                                      {industryData?.get_details?.brief_desc}
                                    </div>
                                    <div className="div_containing_clickable_text_mobile">
                                      <img
                                        className="arrow_icon_mobile"
                                        src={arrow_icon}
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      />{" "}
                                      <span
                                        className="orange_div_text_mobile"
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        Download case study
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem id="rental_asset_tracking">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {toggleValue !== "rental_asset_tracking" ? (
                                <div
                                  className="industry_tile_mobile"
                                  style={{
                                    backgroundImage: `url(${require("../../assets/Industry/rental_asset_tracking.png")})`
                                  }}
                                  onClick={() => {
                                    getData("rental_asset_tracking");
                                  }}
                                >
                                  <img
                                    className="img_icon"
                                    src={
                                      require("../../assets/Home/rental_logo.svg")
                                        .default
                                    }
                                  ></img>
                                  <div className="industry_tile_title_mobile">
                                    Rental Asset Tracking
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="image_div_tile_clicked_mobile"
                                  onClick={() => {
                                    setToggleValue("");
                                  }}
                                >
                                  <img
                                    src={industryData?.image}
                                    className="image_mobile_view"
                                    alt=""
                                  />
                                </div>
                              )}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div>
                              <div className="industry_tile_clicked_div_mobile">
                                <div className="description_industry_tile_mobile">
                                  <div className="desc_icon_div_mobile">
                                    <img
                                      style={{ color: "white" }}
                                      src={industryData?.desc_icon}
                                    />
                                  </div>
                                  <div className="description_industry_tile_title_mobile">
                                    {industryData?.industry}
                                  </div>
                                  <div
                                    className="descriptive_text_mobile"
                                    dangerouslySetInnerHTML={{
                                      __html: industryData?.description
                                    }}
                                  ></div>
                                </div>
                                <div className="usecase_div_mobile">
                                  <div className="usecase_heading_mobile">
                                    Use Cases
                                  </div>
                                  {usecaseData.map(
                                    (industry: any, index: number) => {
                                      return (
                                        <div
                                          className="usecase_options_mobile"
                                          key={index}
                                        >
                                          {industry.name}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {industryData?.get_details?.pdf_link != "" ? (
                                  <div className="orange_div_mobile">
                                    <div className="rulo_text_industry_mobile">
                                      {industryData?.get_details?.brief_desc}
                                    </div>
                                    <div className="div_containing_clickable_text_mobile">
                                      <img
                                        className="arrow_icon_mobile"
                                        src={arrow_icon}
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      />{" "}
                                      <span
                                        className="orange_div_text_mobile"
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        Download case study
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem id="construction">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {toggleValue !== "construction" ? (
                                <div
                                  className="industry_tile_mobile"
                                  style={{
                                    backgroundImage: `url(${require("../../assets/Industry/construction.png")})`
                                  }}
                                  onClick={() => {
                                    getData("construction");
                                  }}
                                >
                                  <img
                                    className="img_icon"
                                    src={
                                      require("../../assets/Home/cons_logo.svg")
                                        .default
                                    }
                                  ></img>
                                  <div className="industry_tile_title_mobile">
                                    Construction
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="image_div_tile_clicked_mobile"
                                  onClick={() => {
                                    setToggleValue("");
                                  }}
                                >
                                  <img
                                    src={industryData?.image}
                                    className="image_mobile_view"
                                    alt=""
                                  />
                                </div>
                              )}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div>
                              <div className="industry_tile_clicked_div_mobile">
                                <div className="description_industry_tile_mobile">
                                  <div className="desc_icon_div_mobile">
                                    <img
                                      style={{ color: "white" }}
                                      src={industryData?.desc_icon}
                                    />
                                  </div>
                                  <div className="description_industry_tile_title_mobile">
                                    {industryData?.industry}
                                  </div>
                                  <div
                                    className="descriptive_text_mobile"
                                    dangerouslySetInnerHTML={{
                                      __html: industryData?.description
                                    }}
                                  ></div>
                                </div>
                                <div className="usecase_div_mobile">
                                  <div className="usecase_heading_mobile">
                                    Use Cases
                                  </div>
                                  {usecaseData.map(
                                    (industry: any, index: number) => {
                                      return (
                                        <div
                                          className="usecase_options_mobile"
                                          key={index}
                                        >
                                          {industry.name}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {industryData?.get_details?.pdf_link != "" ? (
                                  <div className="orange_div_mobile">
                                    <div className="rulo_text_industry_mobile">
                                      {industryData?.get_details?.brief_desc}
                                    </div>
                                    <div className="div_containing_clickable_text_mobile">
                                      <img
                                        className="arrow_icon_mobile"
                                        src={arrow_icon}
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      />{" "}
                                      <span
                                        className="orange_div_text_mobile"
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        Download case study
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem id="industrial_manufacturing">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {toggleValue !== "industrial_manufacturing" ? (
                                <div
                                  className="industry_tile_mobile"
                                  style={{
                                    backgroundImage: `url(${require("../../assets/Industry/industrial_manufacturing.png")})`
                                  }}
                                  onClick={() => {
                                    getData("industrial_manufacturing");
                                  }}
                                >
                                  <img
                                    className="img_icon"
                                    src={
                                      require("../../assets/Home/manu_logo.svg")
                                        .default
                                    }
                                  ></img>
                                  <div className="industry_tile_title_mobile">
                                    Industrial Manufacturing
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="image_div_tile_clicked_mobile"
                                  onClick={() => {
                                    setToggleValue("");
                                  }}
                                >
                                  <img
                                    src={industryData?.image}
                                    className="image_mobile_view"
                                    alt=""
                                  />
                                </div>
                              )}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div>
                              <div className="industry_tile_clicked_div_mobile">
                                <div className="description_industry_tile_mobile">
                                  <div className="desc_icon_div_mobile">
                                    <img
                                      style={{ color: "white" }}
                                      src={industryData?.desc_icon}
                                    />
                                  </div>
                                  <div className="description_industry_tile_title_mobile">
                                    {industryData?.industry}
                                  </div>
                                  <div
                                    className="descriptive_text_mobile"
                                    dangerouslySetInnerHTML={{
                                      __html: industryData?.description
                                    }}
                                  ></div>
                                </div>
                                <div className="usecase_div_mobile">
                                  <div className="usecase_heading_mobile">
                                    Use Cases
                                  </div>
                                  {usecaseData.map(
                                    (industry: any, index: number) => {
                                      return (
                                        <div
                                          className="usecase_options_mobile"
                                          key={index}
                                        >
                                          {industry.name}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {industryData?.get_details?.pdf_link != "" ? (
                                  <div className="orange_div_mobile">
                                    <div className="rulo_text_industry_mobile">
                                      {industryData?.get_details?.brief_desc}
                                    </div>
                                    <div className="div_containing_clickable_text_mobile">
                                      <img
                                        className="arrow_icon_mobile"
                                        src={arrow_icon}
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      />{" "}
                                      <span
                                        className="orange_div_text_mobile"
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        Download case study
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem id="emmisions_management">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {toggleValue !== "emmisions_management" ? (
                                <div
                                  className="industry_tile_mobile"
                                  style={{
                                    backgroundImage: `url(${require("../../assets/Industry/emmisions_management.png")})`
                                  }}
                                  onClick={() => {
                                    getData("emmisions_management");
                                  }}
                                >
                                  <img
                                    className="img_icon"
                                    src={
                                      require("../../assets/Home/emmission_logo.svg")
                                        .default
                                    }
                                  ></img>
                                  <div className="industry_tile_title_mobile">
                                    Emissions Management
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="image_div_tile_clicked_mobile"
                                  onClick={() => {
                                    setToggleValue("");
                                  }}
                                >
                                  <img
                                    src={industryData?.image}
                                    className="image_mobile_view"
                                    alt=""
                                  />
                                </div>
                              )}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div>
                              <div className="industry_tile_clicked_div_mobile">
                                <div className="description_industry_tile_mobile">
                                  <div className="desc_icon_div_mobile">
                                    <img
                                      style={{ color: "white" }}
                                      src={industryData?.desc_icon}
                                    />
                                  </div>
                                  <div className="description_industry_tile_title_mobile">
                                    {industryData?.industry}
                                  </div>
                                  <div
                                    className="descriptive_text_mobile"
                                    dangerouslySetInnerHTML={{
                                      __html: industryData?.description
                                    }}
                                  ></div>
                                </div>
                                <div className="usecase_div_mobile">
                                  <div className="usecase_heading_mobile">
                                    Use Cases
                                  </div>
                                  {usecaseData.map(
                                    (industry: any, index: number) => {
                                      return (
                                        <div
                                          className="usecase_options_mobile"
                                          key={index}
                                        >
                                          {industry.name}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {industryData?.get_details?.pdf_link != "" ? (
                                  <div className="orange_div_mobile">
                                    <div className="rulo_text_industry_mobile">
                                      {industryData?.get_details?.brief_desc}
                                    </div>
                                    <div className="div_containing_clickable_text_mobile">
                                      <img
                                        className="arrow_icon_mobile"
                                        src={arrow_icon}
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      />{" "}
                                      <span
                                        className="orange_div_text_mobile"
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        Download case study
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem id="telecommunications">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {toggleValue !== "telecommunications" ? (
                                <div
                                  className="industry_tile_mobile"
                                  style={{
                                    backgroundImage: `url(${require("../../assets/Industry/telecommunications.png")})`
                                  }}
                                  onClick={() => {
                                    getData("telecommunications");
                                  }}
                                >
                                  <img
                                    className="img_icon"
                                    src={
                                      require("../../assets/Home/telco_logo.svg")
                                        .default
                                    }
                                  ></img>
                                  <div className="industry_tile_title_mobile">
                                    Telecommunications
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="image_div_tile_clicked_mobile"
                                  onClick={() => {
                                    setToggleValue("");
                                  }}
                                >
                                  <img
                                    src={industryData?.image}
                                    className="image_mobile_view"
                                    alt=""
                                  />
                                </div>
                              )}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div>
                              <div className="industry_tile_clicked_div_mobile">
                                <div className="description_industry_tile_mobile">
                                  <div className="desc_icon_div_mobile">
                                    <img
                                      style={{ color: "white" }}
                                      src={industryData?.desc_icon}
                                    />
                                  </div>
                                  <div className="description_industry_tile_title_mobile">
                                    {industryData?.industry}
                                  </div>
                                  <div
                                    className="descriptive_text_mobile"
                                    dangerouslySetInnerHTML={{
                                      __html: industryData?.description
                                    }}
                                  ></div>
                                </div>
                                <div className="usecase_div_mobile">
                                  <div className="usecase_heading_mobile">
                                    Use Cases
                                  </div>
                                  {usecaseData.map(
                                    (industry: any, index: number) => {
                                      return (
                                        <div
                                          className="usecase_options_mobile"
                                          key={index}
                                        >
                                          {industry.name}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {industryData?.get_details?.pdf_link != "" ? (
                                  <div className="orange_div_mobile">
                                    <div className="rulo_text_industry_mobile">
                                      {industryData?.get_details?.brief_desc}
                                    </div>
                                    <div className="div_containing_clickable_text_mobile">
                                      <img
                                        className="arrow_icon_mobile"
                                        src={arrow_icon}
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      />{" "}
                                      <span
                                        className="orange_div_text_mobile"
                                        onClick={() => {
                                          window.open(
                                            industryData?.get_details?.pdf_link,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        Download case study
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        {/* Commented out the emergency response tile */}
                        {/* <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {toggleValue !==
                              "emergency_response_disaster_relief" ? (
                                <div
                                  className="industry_tile_mobile"
                                  style={{
                                    backgroundImage: `url(${require("../../assets/Industry/emergency_response.png")})`
                                  }}
                                  onClick={() => {
                                    getData(
                                      "emergency_response_disaster_relief"
                                    );
                                  }}
                                >
                                  <img
                                    className="img_icon"
                                    src={
                                      require("../../assets/Home/emergency_logo.svg")
                                        .default
                                    }
                                  ></img>
                                  <div className="industry_tile_title_mobile">
                                    Emergency response &amp; disaster relief
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="image_div_tile_clicked_mobile"
                                  onClick={() => {
                                    setToggleValue("");
                                  }}
                                >
                                  <img
                                    src={industryData?.image}
                                    className="image_mobile_view"
                                    alt=""
                                  />
                                </div>
                              )}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div>
                              <div className="industry_tile_clicked_div_mobile">
                                <div className="description_industry_tile_mobile">
                                  <div className="desc_icon_div_mobile">
                                    <img
                                      style={{ color: "white" }}
                                      src={industryData?.desc_icon}
                                    />
                                  </div>
                                  <div className="description_industry_tile_title_mobile">
                                    {industryData?.industry}
                                  </div>
                                  <div
                                    className="descriptive_text_mobile"
                                    dangerouslySetInnerHTML={{
                                      __html: industryData?.description
                                    }}
                                  >
                                  </div>
                                </div>
                                <div className="usecase_div_mobile">
                                  <div className="usecase_heading_mobile">
                                    Use Cases
                                  </div>
                                  {usecaseData.map(
                                    (industry: any, index: number) => {
                                      return (
                                        <div
                                          className="usecase_options_mobile"
                                          key={index}
                                        >
                                          {industry.name}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {industryData?.get_details?.pdf_link!=""?
                                <div className="orange_div_mobile">
                                  <div className="rulo_text_industry_mobile">
                                    {industryData?.get_details?.brief_desc}
                                  </div>
                                </div>
                                :<></>}
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem> */}
                      </Accordion>
                    </div>
                  </div>
                </div>
                <div className="home_left_section_second_row">
                  <div className="custom_rulo_text_div">
                    <div className="custom_rulo_text">
                      Not seeing what you need?
                      <p className="specific_text">
                        Tell us about your specific use case and we’ll work to
                        create a Rulo solution that’s built for you.
                      </p>
                    </div>
                    <button
                      className="blue-button"
                      onClick={() => {
                        navTo("/contact");
                      }}
                    >
                      <div
                        className="flex_row justify-content-center"
                        style={{ gap: "0.5em" }}
                      >
                        <div>
                          <img
                            style={{ width: "1rem" }}
                            src={button_arrow}
                          ></img>
                        </div>
                        <div
                          style={{ height: "fit-content", color: "#ffffff" }}
                        >
                          Custom Rulo
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isTileClicked && (
              <div className="industry_tile_clicked_div">
                <div className="description_industry_tile">
                  <div className="description_industry_tile_title">
                    {industryData?.industry}
                  </div>
                  <div>
                    <img
                      src={close_icon}
                      className="close_icon"
                      onClick={() => {
                        setIsTileClicked(false);
                      }}
                    />
                  </div>
                  <div
                    className="descriptive_text"
                    dangerouslySetInnerHTML={{
                      __html: industryData?.description
                    }}
                  ></div>
                </div>
                <div className="usecase_div">
                  <div className="usecases">
                    <div className="usecase_heading">Use Cases</div>
                    {usecaseData.map((industry: any, index: number) => {
                      return (
                        <div className="usecase_options" key={index}>
                          {industry.name}
                        </div>
                      );
                    })}
                  </div>
                  <div className="usecase_image_div">
                    <img
                      className="desktop_image"
                      src={industryData?.image}
                      alt=""
                    />
                    {industryData?.get_details?.pdf_link != "" ? (
                      <div className="orange_div">
                        <div className="rulo_text_industry">
                          {industryData?.get_details?.brief_desc}
                        </div>
                        <a
                          href={industryData?.get_details?.pdf_link}
                          target="_blank"
                        >
                          <div className="casestudy_text">
                            {industryData?.get_details?.supporting_text}
                          </div>
                        </a>
                        <div>
                          <img className="arrow_icon" src={arrow_icon} />{" "}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div
              className={
                isTileClicked
                  ? "industry_right_side industry_parent industry_overflow_hidden"
                  : "industry_right_side industry_parent"
              }
              style={{ position: "relative" }}
            >
              {/* {isTileClicked && (
                <div className="industry_tile_clicked_div">
                  <div className="description_industry_tile">
                    <div className="description_industry_tile_title">
                      {industryData?.industry}
                    </div>
                    <div>
                      <img
                        src={close_icon}
                        className="close_icon"
                        onClick={() => {
                          setIsTileClicked(false);
                        }}
                      />
                    </div>
                    <div
                      className="descriptive_text"
                      dangerouslySetInnerHTML={{
                        __html: industryData?.description
                      }}
                    ></div>
                  </div>
                  <div className="usecase_div">
                    <div className="usecases">
                      <div className="usecase_heading">Use Cases</div>
                      {usecaseData.map((industry: any, index: number) => {
                        return (
                          <div className="usecase_options" key={index}>
                            {industry.name}
                          </div>
                        );
                      })}
                    </div>
                    <div className="usecase_image_div">
                      <img src={industryData?.image} alt="" />
                      <div className="orange_div">
                        <div className="rulo_text_industry">
                          {industryData?.get_details?.brief_desc}
                        </div>
                        <a
                          href={industryData?.get_details?.pdf_link}
                          target="_blank"
                        >
                          <div className="casestudy_text">
                            {industryData?.get_details?.supporting_text}
                          </div>
                        </a>
                        <div>
                          <img className="arrow_icon" src={arrow_icon} />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
              <div
                className={`full_industry_tiles ${
                  isTileClicked ? "pointer-events-none" : ""
                }`}
              >
                <div
                  id="oil_gas_industry_tiles"
                  className="industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/oil_industry_tile_bg.png")})`
                  }}
                  onClick={() => {
                    getData("oil_gas");
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/oil-pump_logo.svg").default}
                  ></img>
                  <div className="industry_tile_title">Oil / gas</div>
                </div>
                {/* Commented out the crypto mining tile */}
                {/* <div
                  className="industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/crypto_industry_tile_bg.png")})`
                  }}
                  onClick={() => {
                    getData("crypto_mining");
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/crypto_logo.svg").default}
                  ></img>
                  <div className="industry_tile_title">Crypto mining</div>
                </div> */}
                <div
                  className="industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/muni_industry_tile_bg.png")})`
                  }}
                  onClick={() => {
                    getData("municipality");
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/muni_logo.svg").default}
                  ></img>
                  <div className="industry_tile_title">Municipality</div>
                </div>
                <div
                  className="industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/rental_industry_tile_bg.png")})`
                  }}
                  onClick={() => {
                    getData("rental_asset_tracking");
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/rental_logo.svg").default}
                  ></img>
                  <div className="industry_tile_title">
                    Rental / asset tracking
                  </div>
                </div>
                <div
                  className="industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/cons_industry_tile_bg.png")})`
                  }}
                  onClick={() => {
                    getData("construction");
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/cons_logo.svg").default}
                  ></img>
                  <div className="industry_tile_title">Construction</div>
                </div>
                <div
                  className="industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/manu_industry_tile_bg.png")})`
                  }}
                  onClick={() => {
                    getData("industrial_manufacturing");
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/manu_logo.svg").default}
                  ></img>
                  <div className="industry_tile_title">
                    Industrial manufacturing
                  </div>
                </div>
                <div
                  className="industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/emission_industry_tile_bg.png")})`
                  }}
                  onClick={() => {
                    getData("emmisions_management");
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={
                      require("../../assets/Home/emmission_logo.svg").default
                    }
                  ></img>
                  <div className="industry_tile_title">
                    Emissions management
                  </div>
                </div>
                <div
                  className="industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/telco_industry_tile_bg.png")})`
                  }}
                  onClick={() => {
                    getData("telecommunications");
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/telco_logo.svg").default}
                  ></img>
                  <div className="industry_tile_title">Telecommunications</div>
                </div>
                {/* Commented out the emergency response tile */}
                {/* <div
                  className="industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/emer_industry_tile_bg.png")})`
                  }}
                  onClick={() => {
                    getData("emergency_response_disaster_relief");
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={
                      require("../../assets/Home/emergency_logo.svg").default
                    }
                  ></img>
                  <div className="industry_tile_title">
                    Emergency response &amp; disaster relief
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </Grid>
    </Grid>
  );
};
export default Industry;
