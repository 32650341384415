import React, { useState } from "react";
import { moniterArray, solveArray } from "./constant";

const AssetForm = ({
  step,
  formData,
  validation,
  handleValueChange,
}: {
  step: number;
  formData: any;
  validation: any;
  handleValueChange: (name: string, value: any) => void;
}) => {
  const [isLocation, setIsLocation] = useState(false);

  const handleChange = (event: any) => {
    let { name, value, checked } = event.target;
    let arrayValue = formData[name] ? [...formData[name]] : [];

    if (checked) {
      if (arrayValue && arrayValue?.length) {
        arrayValue.push(value);
      } else {
        arrayValue = [value];
      }
    } else {
      arrayValue = arrayValue.filter((val: string) => val != value);
    }

    handleValueChange(name, arrayValue);
  };

  const handleOtherInputChange = (event: any) => {
    let { name, value } = event.target;
    handleValueChange(name, value);
  };

  const handleOtherChange = (event: any) => {
    const { name, checked } = event.target;
    setIsLocation(checked);
    handleValueChange(name, "");
  };

  return (
    <>
      {step === 1 && (
        <div className="rolo-asset-form-block monitor-or-manage">
          <h3 className="rolo-inner-form-title">
            What do you need to monitor or manage?
          </h3>
          <ul className="rolo-checkbox-list">
            {moniterArray.map((moniter: string) => (
              <li key={moniter}>
                <input
                  type="checkbox"
                  id={moniter.split(" ").join()}
                  name="interest"
                  value={moniter}
                  checked={
                    formData["interest"]
                      ? formData["interest"].includes(moniter)
                      : false
                  }
                  onChange={handleChange}
                />
                <label htmlFor={moniter.split(" ").join()}>{moniter}</label>
              </li>
            ))}
          </ul>

          <p>Check all that apply</p>
          <p className="form_error">{validation.interest}</p>
        </div>
      )}

      {(step === 2 || step === 3) && (
        <div className="rolo-asset-form-block trying-to-solve">
          <h3 className="rolo-inner-form-title">
            What are the problems you are trying to solve?
          </h3>
          <h6>
            We have listed some of the most common ones. Feel free to add more
            information so we can help you better.
          </h6>
          <ul className="rolo-checkbox-list">
            {solveArray.map((solve: string) => (
              <li key={solve}>
                <input
                  type="checkbox"
                  id={solve.split(" ").join()}
                  name="details"
                  value={solve}
                  checked={
                    formData["details"]
                      ? formData["details"].includes(solve)
                      : false
                  }
                  onChange={handleChange}
                />
                <label htmlFor={solve.split(" ").join()}>{solve}</label>
              </li>
            ))}

            <li>
              <input
                type="checkbox"
                id="asset_other"
                name="details_other"
                value="Other"
                checked={isLocation}
                onChange={handleOtherChange}
              />
              <label htmlFor="asset_other">Other</label>
            </li>
          </ul>
          <p>Check all that apply</p>
          {isLocation && (
            <div className="rolo-text-field">
              <textarea
                placeholder="Enter description of other operational issues."
                name="details_other"
                onChange={handleOtherInputChange}
                value={formData.details_other}
              ></textarea>
            </div>
          )}
          <p className="form_error">{validation.details}</p>
        </div>
      )}
    </>
  );
};

export default AssetForm;
