import React, { useState, useEffect } from "react";
import rulo_graphic from "../../assets/Company/rulo_graphic.svg";
import rulo_matrix from "../../assets/rulo_matrix.svg";
import vijay from "../../assets/Company/vijay.png";
import kyle from "../../assets/Company/kyle.png";
// import brian from "../../assets/Company/brian.png";
import tyler from "../../assets/Company/tyler.png";
import linkedin from "../../assets/Company/linkedin.svg";
import { Grid } from "@material-ui/core";
import "./Company.css";

const Company = () => {
  const [ismobileview, setIsmobileview] = useState(false);
  useEffect(() => {
    document.title = "Rulo | About the Company";
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 850) {
        setIsmobileview(false);
        let row: any = document.getElementById("company_overview_grid");
        let textContent: any = document.getElementById(
          "company_overview_content"
        );
        row.style.maxHeight = String(textContent?.clientHeight * 1.05) + "px";
        row.style.minHeight = String(textContent?.clientHeight * 1.05) + "px";
        if (window.innerWidth > 1200 && window.innerWidth < 1400) {
          row.style.maxHeight = String(textContent?.clientHeight * 1.1) + "px";
          row.style.minHeight = String(textContent?.clientHeight * 1.1) + "px";
        }
        if (window.innerWidth > 1400 && window.innerWidth < 1600) {
          row.style.maxHeight = String(textContent?.clientHeight * 1.4) + "px";
          row.style.minHeight = String(textContent?.clientHeight * 1.4) + "px";
        }
        if (window.innerWidth > 1599 && window.innerWidth < 1900) {
          row.style.maxHeight = String(textContent?.clientHeight * 1.15) + "px";
          row.style.minHeight = String(textContent?.clientHeight * 1.15) + "px";
        }
        if (window.innerWidth > 1900 && window.innerWidth < 2100) {
          row.style.maxHeight =
            String(textContent?.clientHeight * 1.405) + "px";
          row.style.minHeight =
            String(textContent?.clientHeight * 1.405) + "px";
        }
        if (window.innerWidth > 2100 && window.innerWidth < 2400) {
          row.style.maxHeight = String(textContent?.clientHeight * 1) + "px";
          row.style.minHeight = String(textContent?.clientHeight * 1) + "px";
        }
        if (window.innerWidth > 2400 && window.innerWidth < 2700) {
          row.style.maxHeight = String(textContent?.clientHeight * 1.16) + "px";
          row.style.minHeight = String(textContent?.clientHeight * 1.16) + "px";
        }
      } else {
        setIsmobileview(true);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Grid>
      <Grid className="company_banner">
        <div className="banner_text">
          {/* <p className="analytics_text">Analytics grounded in</p> */}
          Analytics grounded in{" "}
          <span className="industrial_text">industrial expertise</span>
        </div>
        {/* <div className="banner_text">
          <p className="analytics_text">Analytics grounded in</p>
          <p className="industrial_text">industrial expertise</p>
        </div> */}
        <div className="banner_text1">
          We are the pioneers in our industry, bringing over many years of
          collective experience to deliver superior data, analytics and
          automation solutions to serve our customers.
        </div>
      </Grid>
      <Grid className="grid_item_1" id="company_overview_grid">
        <div className="left_section_company ">
          <div className="pl-28per_company ">
            <img src={rulo_matrix}></img>
            <label className="company_overview">Company overview</label>
          </div>
        </div>
        {!ismobileview ? (
          <>
            <div className="right_section_company">
              <div
                id="company_overview_content"
                style={{ paddingTop: "6.4375em" }}
              >
                <div className="right_section_text_company">
                  What's in a name?{" "}
                  <span className="for_span">For us, it's quite a lot.</span>
                </div>
                <div className="right_section_subtext">
                  <p>
                    When we sought out to rebrand our company, we kept coming
                    back to the principles of Simplicity, Security and Cost.
                    We're literally the convergence of three, the Reuleaux
                    triangle and the core.
                  </p>
                  <p>
                    We offer the most user friendly product over any of our
                    competitors, at the lowest price around. And we do this
                    without sacrificing security.
                  </p>
                  <p>
                    Founded in 2018, we are the pioneers in our industry,
                    bringing over many years of collective experience to deliver
                    superior data, analytics and automation solutions to serve
                    our customers. Our leaders have spent their entire
                    professional careers on how best to manage assets and
                    mitigate risk while bringing innovative solutions to market.
                    We credit our strength and stability to the collaborative
                    partnerships we have built with our customers.
                  </p>
                </div>
              </div>
            </div>
            <div className="rulo_triangle">
              <img src={rulo_graphic} className="rulo_graphic" alt="" />
            </div>
          </>
        ) : (
          <>
            <div className="rulo_triangle">
              <img src={rulo_graphic} className="rulo_graphic" alt="" />
            </div>
            <div className="right_section_company" style={{ flexGrow: 1 }}>
              <div className="right_section_text_company">
                What's in a name?{" "}
                <span className="for_span">For us, it's quite a lot.</span>
              </div>
              <div className="right_section_subtext">
                <p>
                  When we sought out to rebrand our company, we kept coming back
                  to the principles of Simplicity, Security and Cost. We're
                  literally the convergence of three, the Reuleaux triangle and
                  the core.
                </p>
                <p>
                  We offer the most user friendly product over any of our
                  competitors, at the lowest price around. And we do this
                  without sacrificing security.
                </p>
                <p>
                  Founded in 2018, we are the pioneers in our industry, bringing
                  over many years of collective experience to deliver superior
                  data, analytics and automation solutions to serve our
                  customers. Our leaders have spent their entire professional
                  careers on how best to manage assets and mitigate risk while
                  bringing innovative solutions to market. We credit our
                  strength and stability to the collaborative partnerships we
                  have built with our customers.
                </p>
              </div>
            </div>
          </>
        )}
      </Grid>
      <Grid className="grid_item_2">
        <div className="left_section_company ">
          <div className="pl-28per_company ">
            <img src={rulo_matrix}></img>
            <label className="team">Team</label>
          </div>
        </div>
        <div className="team_right_side">
          <div className="full_right_side">
            <div className="right_section_company_vijay">
              <div className="right_section_text_company_vijay">
                <img src={vijay} className="vijay_img" alt="" />
              </div>
            </div>
            <div className="right_section_item">
              <div className="right_item1">
                <div className="vijay_designation">President</div>
                <div className="vijay_icon_div">
                  <div className="vijay_txt">Vijay Janardhan</div>
                  <div className="linkedin_icon">
                    <img
                      src={linkedin}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://www.linkedin.com/in/vijay-janardhan-12aa2a85/",
                          "_blank"
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="about_vijay">
                  <p>
                    Vijay has spent his entire professional career studying,
                    analyzing and developing asset management solutions. He
                    brings over 20 years of industry experience to Rulo. After
                    receiving a Bachelor of Engineering in Mechanical
                    Engineering from College of Engineering-Guindy, Chennai,
                    India, Vijay continued to dive deep into the world of
                    AI-based autonomous systems by earning a dual Masters Degree
                    in Electrical and Mechanical Engineering from the University
                    of Missouri-Rolla. The vast majority of his career was spent
                    at Caterpillar, where he broadened his knowledge of asset
                    monitoring and management needs. Vijay further specialized
                    in the Oil and Gas Industry, leading global diverse
                    cross-functional teams to deliver Advanced Condition
                    Monitoring solutions. His findings unveiled a simple idea-
                    assets require similar monitoring and intervention needs.
                    While there is a broad spectrum of limits and levels, the
                    types of monitoring and automation are consistent throughout
                    the industry.
                  </p>
                  <p>
                    Vijay holds 12 patents in his field: 5 in machine
                    productivity, 4 in energy storage and power management and 3
                    in advanced condition monitoring. Vijay has a deep and
                    comprehensive understanding of asset monitoring and the
                    associated needs of plant managers to operate at capacity.
                    This combined with his love of technology and innovation,
                    Vijay brings a unique and comprehensive wealth of knowledge
                    to the Rulo leadership team. He is influential in design and
                    deployment of current and future releases and is
                    instrumental in deploying the Rulo vision.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="full_right_side">
            <div className="right_section_company_vijay">
              <div className="right_section_text_company_vijay">
                <img src={brian} className="vijay_img" alt="" />
              </div>
            </div>
            <div className="right_section_item">
              <div className="right_item1">
                <div className="vijay_designation">Advisor</div>
                <div className="vijay_icon_div">
                  <div className="vijay_txt">Brian Edge</div>
                  <div className="linkedin_icon">
                    <img
                      src={linkedin}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://www.linkedin.com/in/brian-edge/",
                          "_blank"
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="about_vijay">
                  <p>
                    Brian Edge has seven years of industry experience in the
                    upstream sector in both technical and operational
                    engineering roles. He has worked most major basins onshore
                    US, as well as offshore assets in the Gulf of Mexico. One of
                    Brian's areas of focus has been finding more efficient ways
                    to leverage data to increase productivity, decrease
                    expenses, and optimize asset development. Brian holds a B.S.
                    in Petroleum Engineering from the University of Oklahoma and
                    an MBA from Harvard Business School.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="full_right_side">
            <div className="right_section_company_vijay">
              <div className="right_section_text_company_vijay">
                <img src={tyler} className="vijay_img" alt="" />
              </div>
            </div>
            <div className="right_section_item">
              <div className="right_item1">
                <div className="vijay_designation">Advisor</div>
                <div className="vijay_icon_div">
                  <div className="vijay_txt">Tyler Hassen</div>
                  <div className="linkedin_icon">
                    <img
                      src={linkedin}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://www.linkedin.com/in/tyler-hassen-802b4a12/",
                          "_blank"
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="about_vijay">
                  <p>
                    Tyler Hassen is the president of Basin Drilling Tools and
                    has served in a variety of executive positions at Basin
                    since joining the organization in 2014. Before joining Basin
                    Holdings, Tyler worked in the Global Energy Group at Morgan
                    Stanley from 2005 to 2008. Mr. Hassen holds an A.B. from
                    Princeton University.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="full_right_side">
            <div className="right_section_company_vijay">
              <div className="right_section_text_company_vijay">
                <img src={kyle} className="vijay_img" alt="" />
              </div>
            </div>
            <div className="right_section_item">
              <div className="right_item1">
                <div className="vijay_designation">Advisor</div>
                <div className="vijay_icon_div">
                  <div className="vijay_txt">Kyle Scholl</div>
                  <div className="linkedin_icon">
                    <img
                      src={linkedin}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://www.linkedin.com/in/kyle-scholl/",
                          "_blank"
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="about_vijay">
                  <p>
                    Kyle Scholl has over 30 years of international product
                    development experience at Caterpillar Inc. including Global
                    Product Manager roles in systems and components for Energy &
                    Transportation, Construction and Mining machines. He has led
                    product development team innovations in engines, electronics
                    system integration, drivetrains, and hydraulics
                    technologies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
export default Company;
