import "../../App.css";
import React, { useEffect, useRef, useState } from "react";
import "swiper/css/bundle";
import "./Home.css";
import getDataFromS3 from "../../service/services";
import { useNavigate } from "react-router-dom";
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  EffectFlip,
  EffectCoverflow
} from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Hidden } from "@material-ui/core";
import ReactInterval from "react-interval";

let touchstartX: any;
let touchendX: any;
let mobileIndustryTileChangeTimeout: boolean = true;
let mobileBenefitsTabChangeTimeout: boolean = true;
let mobileIndustryActiveTile: number = 1;
const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname: string) => navigate(`${pathname}`);
};

const Home = () => {
  const [blogData, setData1] = useState<any[]>([]);
  const [smallScreen, setSmallScreen] = useState<boolean>(false);
  const [benefitsActive, setBenefitsActive] = useState<any>([
    { is_active: true, title: "Custom reports" },
    { is_active: false, title: "Flash store and forward" },
    { is_active: false, title: "Reliability" },
    { is_active: false, title: "Zero-trust security" }
  ]);
  const [mobileIndustryTileActive, setMobileIndustryTileActive] = useState<
    number
  >(0);

  const [animationRunning, setAnimationRunning] = useState<boolean>(false);
  const [companyActive, setCompanyActive] = useState<number>(0);
  const [benefitsTileOnClick, setBenefitsTileOnClick] = useState(true);
  const [counts, setCounts] = useState(0);

  const getActiveBenefitsTab = () => {
    let benefitsActive_copy = Object.assign([], benefitsActive);
    return benefitsActive_copy.findIndex((e: any) => e.is_active === true);
  };

  useEffect(() => {
    document.title = "Industrial Connectivity and Automation | Rulo";
  }, []);

  const changeBenefitsTab = (index: number) => {
    let benefitsActive_copy = Object.assign([], benefitsActive);
    benefitsActive_copy[index].is_active = true;
    benefitsActive_copy.forEach((element: any, index1: any) => {
      if (index1 != index) {
        element.is_active = false;
      }
    });

    setBenefitsActive(benefitsActive_copy);
  };

  function changeIndustryTile(dir: any) {
    if (dir === "right") {
      if (mobileIndustryActiveTile > 1) {
        setMobileIndustryTileActive((prevState: any) => {
          --prevState;
          mobileIndustryActiveTile = prevState;
          return mobileIndustryActiveTile;
        });
      }
    } else if (dir === "left") {
      if (mobileIndustryActiveTile < 8) {
        setMobileIndustryTileActive((prevState: any) => {
          ++prevState;
          mobileIndustryActiveTile = prevState;
          return mobileIndustryActiveTile;
        });
      }
    }
  }
  function scrollHorizontalBenefitsTitleRow(index: any, from: any) {
    changeBenefitsTab(index);
    if (from !== "callback") {
      let sliderBenefitsHome = document.getElementById("myBenefitsSwiper");
      (sliderBenefitsHome as any).swiper.slideTo(index, 500, false);
    }
    let myFlexDiv = document.getElementById("benefitsTitleRow");
    let rowGap = 0;
    let visibleWidth = 0;
    let sum = 0;
    let posArray = [0];
    if (myFlexDiv) {
      const cssObj = window.getComputedStyle(myFlexDiv, null);
      rowGap = Number(
        cssObj
          .getPropertyValue("row-gap")
          .substring(0, cssObj.getPropertyValue("row-gap").length - 2)
      );
      visibleWidth = Number(
        cssObj
          .getPropertyValue("width")
          .substring(0, cssObj.getPropertyValue("width").length - 2)
      );
    }
    for (let i = 0; i < 3; i++) {
      let obj = document.getElementById(`benefitsTitle${i}`);
      if (obj) {
        sum += obj.clientWidth + (index == 2 ? 0 : rowGap);
        posArray.push(sum);
      }
    }

    if (myFlexDiv) {
      if (index != 1) {
        // myFlexDiv.scrollLeft = posArray[index];
        myFlexDiv.scrollTo({ left: posArray[index], behavior: "smooth" });
      } else {
        //   myFlexDiv.scrollLeft = sum / 2 - visibleWidth / 1.7;
        myFlexDiv.scrollTo({
          left: sum / 2 - visibleWidth / 1.7,
          behavior: "smooth"
        });
      }
    }
  }
  function checkDirection() {
    if (
      touchendX < touchstartX &&
      mobileIndustryTileChangeTimeout &&
      Math.abs(touchendX - touchstartX) > 50
    ) {
      mobileIndustryTileChangeTimeout = false;
      setTimeout(() => {
        mobileIndustryTileChangeTimeout = true;
      }, 500);
      changeIndustryTile("left");
    }
    if (
      touchendX > touchstartX &&
      mobileIndustryTileChangeTimeout &&
      Math.abs(touchendX - touchstartX) > 50
    ) {
      mobileIndustryTileChangeTimeout = false;
      setTimeout(() => {
        mobileIndustryTileChangeTimeout = true;
      }, 500);
      changeIndustryTile("right");
    }
  }
  useEffect(() => {
    addEventListenersforIndustryTiles();
  });
  function addEventListenersforIndustryTiles() {
    let industryOuterDiv = document.getElementById(
      "mobileIndustryTileOuterDiv"
    );
    industryOuterDiv?.removeEventListener("touchstart", () => {});
    industryOuterDiv?.removeEventListener("touchend", () => {});
    industryOuterDiv?.addEventListener("touchstart", e => {
      touchstartX = e.changedTouches[0].screenX;
    });

    industryOuterDiv?.addEventListener("touchend", e => {
      touchendX = e.changedTouches[0].screenX;

      checkDirection();
    });
  }
  useEffect(() => {
    getDataFromS3("blog/blog.json").then((data: any[]) => {
      let data_copy = data;
      let date_array: any[] = [];
      data_copy.forEach((item: any) => [
        date_array.push(Number(new Date(item.date_time)))
      ]);
      data_copy.sort(sortFn("date_time", "dsc"));
      setData1(data_copy);
    });
    function sortFn(attr: any, order: any) {
      return (a: any, b: any) => {
        let start = new Date(a[attr]);
        let end = new Date(b[attr]);
        return sortByValue(start, end, order);
      };
    }
    function sortByValue(start: any, end: any, order: any): number {
      if (start > end) {
        return order == "asc" ? 1 : -1;
      } else if (start < end) {
        return order == "asc" ? -1 : 1;
      } else {
        return 0;
      }
    }
  }, []);
  useEffect(() => {
    if (window.innerWidth > 850) {
      setSmallScreen(false);
    } else {
      setSmallScreen(true);
    }
  });
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 850) {
        setSmallScreen(false);
      } else {
        setSmallScreen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // useEffect(() => {
  //   ;
  // }, []);

  const navigateSearch = useNavigateSearch();
  const navTo = (path: string) => {
    window.scrollTo(0, 0);
    navigateSearch(path);
  };
  let counter = 0;
  let togglerBrowser = true;

  function tabToggler() {
    togglerBrowser = !togglerBrowser;
  }

  useEffect(() => {
    window.addEventListener("visibilitychange", () => {
      tabToggler();
    });
  }, []);

  function onClickHomeCompanyDot(item: any) {
    let myCompanySlider = document.getElementById("home_company_slider");
    (myCompanySlider as any).swiper.slideTo(item, 500, false);
  }

  return (
    <div>
      <Swiper 
     autoplay={{
      delay: 9500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    }}
  
    pagination={{
      clickable: true,
    }}
      loop={true}
      modules={[Pagination,Autoplay]} className="mySwiper rolo-hero-banner">
       <SwiperSlide>
        <div className="home_banner">
          <div className="home_banner_grid">
            <div className="home-banner-grid-item-1 home_flex_column justify-content-end">
              <label className="banner-title">
                Monitor, control and manage your assets, <span>in real time</span>
              </label>
              <p>
                Using our self-service platform, securely connect your assets to the cloud and manage them effectively.  Empower your people to take action now.
              </p>
              <p>
                <span>
                  Easy set-up / Transparent pricing / Plug and play design
                </span>
              </p>
            </div>
            <div className="home-banner-grid-item-2">
              <div className="home-banner-grid-item-2-content">
                <div
                  style={{
                    position: "relative",
                    isolation: "isolate",
                    transform: "scale(1.125,1.125)"
                  }}
                >
                  <img
                    className="home_banner_ipad"
                    src={require("../../assets/Home/home_banner_ipad.png")}
                  ></img>
                  <img
                    className="home_banner_iphone"
                    src={require("../../assets/Home/home_banner_iphone.png")}
                  ></img>
                  {/* <img
                    className="home_banner_rulo_dashboard"
                    src={
                      require("../../assets/Home/home_banner_rulo_dashboard.png")
                        
                    }
                  ></img> */}
                  <img
                    className="home_banner_4s"
                    src={require("../../assets/Home/home_banner_4s.png")}
                  ></img>
                </div>
              </div>
            </div>
            <div className="home-banner-grid-item-3 home_flex_column">
              <div>
                <button
                  className="orange-button home-banner-button-pd"
                  onClick={() => {
                    navTo("/platform");
                  }}
                >
                  <div
                    className="flex_row justify-content-center"
                    style={{ gap: "0.5em" }}
                  >
                    <div>
                      <img
                        style={{ width: "1rem" }}
                        src={require("../../assets/button_arrow.svg").default}
                      ></img>
                    </div>
                    <div style={{ height: "fit-content" }}>Explore options</div>
                  </div>
                </button>
                <div className="home-banner-button-text-below"></div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="home_banner slider02">
          <div className="home_banner_grid">
            <div className="home-banner-grid-item-1 home_flex_column justify-content-end">
              <label className="banner-title">
                Reliable site visibility - <span>simple and secure.</span>
              </label>
              <p>
                With a wide variety of connectivity interfaces and efficient data management, Rulo’s
                EdgeTrak line of controllers let’s you connect everything to the cloud at a low cost.
              </p>
              <p>
                <span>
                  Easy set-up  /  Transparent pricing  /  Plug and play design
                </span>
              </p>
            </div>
         
            <div className="home-banner-grid-item-3 home_flex_column">
              <div>
                <button
                  className="orange-button home-banner-button-pd"
                  onClick={() => {
                    navTo("/platform");
                  }}
                >
                  <div
                    className="flex_row justify-content-center"
                    style={{ gap: "0.5em" }}
                  >
                    <div>
                      <img
                        style={{ width: "1rem" }}
                        src={require("../../assets/button_arrow.svg").default}
                      ></img>
                    </div>
                    <div style={{ height: "fit-content" }}>Explore options</div>
                  </div>
                </button>
                <div className="home-banner-button-text-below"></div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    
      <SwiperSlide>
        <div className="home_banner slider01" >
          <div className="home_banner_grid">
            <div className="home-banner-grid-item-1 home_flex_column justify-content-end">
              <label className="banner-title">
              Affordable startup costs.<br/> Low operating costs.<span></span> <span>Quick return.</span>
              </label>
              <p>
                  Fully remotely configured edge devices help you track performance and optimize your operations.<br/> 
                  Smart operations management at your fingertips.
              </p>
              <p>
                <span>
                   Easy set-up  /  Transparent pricing  /  Plug and play design
                </span>
              </p>
            </div>
         
            <div className="home-banner-grid-item-3 home_flex_column">
              <div>
                <button
                  className="orange-button home-banner-button-pd"
                  onClick={() => {
                    navTo("/platform");
                  }}
                >
                  <div
                    className="flex_row justify-content-center"
                    style={{ gap: "0.5em" }}
                  >
                    <div>
                      <img
                        style={{ width: "1rem" }}
                        src={require("../../assets/button_arrow.svg").default}
                      ></img>
                    </div>
                    <div style={{ height: "fit-content" }}>Explore options</div>
                  </div>
                </button>
                <div className="home-banner-button-text-below"></div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="home_banner last-banner">
          <div className="home_banner_grid">
            <div className="home-banner-grid-item-1 home_flex_column justify-content-end">
              <label className="banner-title">
              Unique equipment?  Talk to us about <span>customization.</span>
              </label>
              <p>
              Our platform was designed for mixed fleet operations.  Whether you have equipment from different manufacturers or just different kinds of equipment, we can help you connect them up with little effort.
              </p>
              <p>
                <span>
                  Easy set-up  /  Transparent pricing  /  Plug and play design
                </span>
              </p>
            </div>
            <div className="home-banner-grid-item-2">
              <div className="home-banner-grid-item-2-content">
                <div
                  style={{
                    position: "relative",
                    isolation: "isolate",
                    transform: "scale(1.125,1.125)"
                  }}
                >
                  <img
                    className="home_banner_ipad"
                    src={require("../../assets/homebg04.png")}
                  ></img>
                  {/* <img
                    className="home_banner_iphone"
                    src={require("../../assets/homebg04.png")}
                  ></img>
                  <img
                    className="home_banner_rulo_dashboard"
                    src={
                      require("../../assets/Home/home_banner_rulo_dashboard.png")
                        
                    }
                  ></img>
                  <img
                    className="home_banner_4s"
                    src={require("../../assets/Home/home_banner_4s.png")}
                  ></img> */}
                </div>
              </div>
            </div>
            <div className="home-banner-grid-item-3 home_flex_column">
              <div>
                <button
                  className="orange-button home-banner-button-pd"
                  onClick={() => {
                    navTo("/platform");
                  }}
                >
                  <div
                    className="flex_row justify-content-center"
                    style={{ gap: "0.5em" }}
                  >
                    <div>
                      <img
                        style={{ width: "1rem" }}
                        src={require("../../assets/button_arrow.svg").default}
                      ></img>
                    </div>
                    <div style={{ height: "fit-content" }}>Explore options</div>
                  </div>
                </button>
                <div className="home-banner-button-text-below"></div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      </Swiper>
      
      <section>
        <div className="home_flex_row">
          <div className="home_left_section ">
            <div className=" left_section_home">
              <div className="d-flex align-items-center home_margin_left">
                <img
                  style={{ width: "1.25rem", marginRight: "1rem" }}
                  src={require("../../assets/rulo_matrix.svg").default}
                ></img>

                <label className="home_left_sec_header">System overview</label>
              </div>
              <div className="home_left_section_second_row">
                <div className="d-flex align-items-center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navTo("/platform");
                    }}
                  >
                    <span className="left_section_sub_text_home">Details</span>
                    <img
                      style={{ height: "0.625rem" }}
                      src={
                        require("../../assets/Home/orange_arrow.svg").default
                      }
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" home_right_side" style={{ flexGrow: 1 }}>
            <div className="home_sys_over_title">
              Industry-leading,
              <span style={{ color: "#FF6900" }}> fully integrated </span>{" "}
              <span> asset management platform </span>
            </div>
            <div className="home_sys_over_grid">
              <div>
                <img
                  src={require("../../assets/Home/home_sys_ov_eye.svg").default}
                ></img>
                <label>Site visibility</label>
              </div>
              <div>
                <img
                  src={
                    require("../../assets/Home/home_sys_ov_graph.svg").default
                  }
                ></img>
                <label>Reliable data</label>
              </div>
              <div>
                <img
                  src={
                    require("../../assets/Home/home_sys_ov_traffic_signal.svg")
                      .default
                  }
                ></img>
                <label>Easy-to-read UI</label>
              </div>
              <div>
                <img
                  src={
                    require("../../assets/Home/home_sys_ov_robot_arm.svg")
                      .default
                  }
                ></img>
                <label>Equipment control</label>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonails section start */}
      <section className="rolo-testi">
      <Swiper 
       effect={'coverflow'}
       slidesPerView={3}
    //  autoplay={{
    //   delay: 4500,
    //   disableOnInteraction: false,
    // }}
    centeredSlides={true}
    loop={true}
    coverflowEffect={{
      rotate: 0,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    }}
    
    pagination={{
      clickable: true,
    }}
    breakpoints={{
      320: {
        centeredSlides: false,
        slidesPerView: 1,
        coverflowEffect:{
          stretch: -20,
          depth: 0,
        }
      },
      767: {
        slidesPerView: 2,
        coverflowEffect:{
          stretch: -50,
        }
      },
      768: {
        slidesPerView: 2,
        coverflowEffect:{
          stretch: -60,
        }
      },
      991: {
        slidesPerView: 2,
        coverflowEffect:{
          stretch: -60,
        }
      },
      1024: {
        slidesPerView: 2.6,
        coverflowEffect:{
          stretch: -80,
        }
      },
    }}
 
      modules={[Pagination,Autoplay,EffectCoverflow]} className="mySwiper rolo-hero-banner rolo-testi__container">
        <SwiperSlide>
          <div className="rolo-testi-box">
              <p>
                "Reading a digital meter, writing down what you see with a pencil in a tally book, then going home to type it into a computer is wholly wasteful in the world we live in today. The world is moving fast outside of these office walls, and I want a service and technology partner who continues to improve and continues to evolve.  I’ve found that in Rulo."
              </p>
              <div className="rolo-custo-content">
                <div className="rolo-custo-name">Chris W.</div>
                <span className="rolo-custo-desig">COO Rimrock Resource Operating, LLC, OK</span>
              </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rolo-testi-box">
              <p>
              "It has enhanced the utilization of our time so that we can take on other projects instead of just having to stay and focus on that one thing. It’s a great system and it works perfectly for what we need."
              </p>
              <div className="rolo-custo-content">
                <div className="rolo-custo-name">Naomi D.</div>
                <span className="rolo-custo-desig">Town of Ninnekah, OK</span>
              </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rolo-testi-box">
              <p>
              "The Rulo platform is the most versatile, compact, and reliable, remote data collection/control units I have ever worked with. In the 15+ years I have been working in controls, Rulo by far outshines all competitors. I would recommend, and do recommend, this system for any and all customers looking for Data Collection or Remote Operations Applications."
              </p>
              <div className="rolo-custo-content">
                <div className="rolo-custo-name">Loyd W.</div>
                <span className="rolo-custo-desig">Action Energy Services, WY</span>
              </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rolo-testi-box">
              <p>
              "Rebellion first met with Rulo in the fall of 2018, it was immediately clear to us that Rulo was going to be our solution. The real kicker for us and this opportunity was the exceptional service at prices that our marginal wells could economically justify. We are currently planning to install pipeline systems, SWD facilities, and multi-pad well sites with different artificial lift, all monitored and automated with Rulo."
              </p>
              <div className="rolo-custo-content">
                <div className="rolo-custo-name">Eric S.</div>
                <span className="rolo-custo-desig">E&P Company, OK</span>
              </div>
          </div>
        </SwiperSlide>
        </Swiper>
      </section>
       {/* testimonails section end */}
      <section style={{ background: "#F1F3F4" }}>
        <div className="home_flex_row">
          <div className="home_left_section ">
            <div className=" left_section_home">
              <div className="d-flex align-items-center home_margin_left">
                <img
                  style={{ width: "1.25rem", marginRight: "1rem" }}
                  src={require("../../assets/rulo_matrix.svg").default}
                ></img>
                <label className="home_left_sec_header">Industries</label>
              </div>
              <div className="home_left_section_second_row">
                <div className="d-flex align-items-center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navTo("/industry");
                    }}
                  >
                    <span className="left_section_sub_text_home">View all</span>
                    <img
                      style={{ height: "0.625rem" }}
                      src={
                        require("../../assets/Home/orange_arrow.svg").default
                      }
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!smallScreen && (
            <div className=" home_right_side home_industry_parent">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.25rem"
                }}
              >
                <div
                  className="home_industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/oil_industry_tile_bg.png")})`
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/oil-pump_logo.svg").default}
                  ></img>
                  <div className="home_industry_tile_title">Oil / gas</div>
                  <div className="home_industry_tile_desc">
                    Reduce downtime. Pump by exception. Get oil pick up
                    notifications. And increase productivity.
                  </div>
                </div>
                {/* <div
                  className="home_industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/crypto_industry_tile_bg.png")})`
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/crypto_logo.svg").default}
                  ></img>
                  <div className="home_industry_tile_title">Crypto mining</div>
                  <div className="home_industry_tile_desc">
                    Power and cooling systems issues are some of the biggest
                    causes of downtime. Be in the know, with temperature,
                    humidity and power monitoring to increase uptime.
                  </div>
                </div> */}
                <div
                  className="home_industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/muni_industry_tile_bg.png")})`
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/muni_logo.svg").default}
                  ></img>
                  <div className="home_industry_tile_title">Municipality</div>
                  <div className="home_industry_tile_desc">
                    Know when you have a power failure or when your back up
                    system failed. Avoid spills or low head pump failures.{" "}
                  </div>
                </div>
                <div
                  className="home_industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/rental_industry_tile_bg.png")})`
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/rental_logo.svg").default}
                  ></img>
                  <div className="home_industry_tile_title">
                    Rental / asset tracking
                  </div>
                  <div className="home_industry_tile_desc">
                    Know when your equipment is started or stopped. Get a
                    notification when fuel is low. Lack of fuel shutdowns can
                    quickly become expensive.{" "}
                  </div>
                </div>
                <div
                  className="home_industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/cons_industry_tile_bg.png")})`
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/cons_logo.svg").default}
                  ></img>
                  <div className="home_industry_tile_title">Construction</div>
                  <div className="home_industry_tile_desc">
                    Using run hours, idle time and fuel consumption, track your
                    equipment utilization.{" "}
                  </div>
                </div>
                <div
                  className="home_industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/manu_industry_tile_bg.png")})`
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/manu_logo.svg").default}
                  ></img>
                  <div className="home_industry_tile_title">
                    Industrial manufacturing
                  </div>
                  <div className="home_industry_tile_desc">
                    Connect your operations and track your processes.{" "}
                  </div>
                </div>
                <div
                  className="home_industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/emission_industry_tile_bg.png")})`
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={
                      require("../../assets/Home/emmission_logo.svg").default
                    }
                  ></img>
                  <div className="home_industry_tile_title">
                    Emissions management
                  </div>
                  <div className="home_industry_tile_desc">
                    Maintain compliance. Abnormal emissions is a sign of lost
                    productivity.{" "}
                  </div>
                </div>
                <div
                  className="home_industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/telco_industry_tile_bg.png")})`
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/telco_logo.svg").default}
                  ></img>
                  <div className="home_industry_tile_title">
                    Telecommunications
                  </div>
                  <div className="home_industry_tile_desc">
                    Reduce infrastructure maintenance costs using remote
                    monitoring.{" "}
                  </div>
                </div>
                <div
                  className="home_industry_tile"
                  style={{
                    backgroundImage: `url(${require("../../assets/Home/agri_industry_tile_bg.png")})`
                  }}
                >
                  <img
                    style={{ height: "4.375rem" }}
                    src={require("../../assets/Home/muni_logo.svg").default}
                  ></img>
                  <div className="home_industry_tile_title">Agriculture</div>
                  <div className="home_industry_tile_desc">
                    Know when you have a power failure or when your back up
                    system failed. Avoid spills or low head pump failures.{" "}
                  </div>
                </div>
                {/* <div
                className="home_industry_tile"
                style={{
                  backgroundImage: `url(${require("../../assets/Home/emer_industry_tile_bg.png")})`
                }}
              >
                <img
                  style={{ height: "4.375rem" }}
                  src={require("../../assets/Home/emergency_logo.svg").default}
                ></img>
                <div className="home_industry_tile_title">
                  Emergency response &amp; disaster relief
                </div>
                <div className="home_industry_tile_desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  faucibus enim auctor magna rhoncus, in vulputate elit tempus.{" "}
                </div>
              </div> */}
              </div>
            </div>
          )}
          {smallScreen && (
            <>
              <Swiper
                style={{ marginTop: "1.25rem" }}
                id="mySwiper"
                modules={[Autoplay, Pagination, Scrollbar]}
                spaceBetween={50}
                slidesPerView={"auto"}
                onSlideChange={e => {
                  setMobileIndustryTileActive(e.activeIndex);
                }}
              >
                {
                  <SwiperSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                      }}
                    >
                      <div
                        className="home_industry_tile"
                        style={{
                          backgroundImage: `url(${require("../../assets/Home/oil_industry_tile_bg.png")})`
                        }}
                      >
                        <img
                          style={{ height: "4.375rem" }}
                          src={
                            require("../../assets/Home/oil-pump_logo.svg")
                              .default
                          }
                        ></img>
                        <div className="home_industry_tile_title">
                          Oil / gas
                        </div>
                        <div className="home_industry_tile_desc">
                          Reduce downtime. Pump by exception. Get oil pick up
                          notifications. And increase productivity.
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                }
                {/* {
                  <SwiperSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                      }}
                    >
                      <div
                        className="home_industry_tile"
                        style={{
                          backgroundImage: `url(${require("../../assets/Home/crypto_industry_tile_bg.png")})`
                        }}
                      >
                        <img
                          style={{ height: "4.375rem" }}
                          src={
                            require("../../assets/Home/crypto_logo.svg").default
                          }
                        ></img>
                        <div className="home_industry_tile_title">
                          Crypto mining
                        </div>
                        <div className="home_industry_tile_desc">
                          Power and cooling systems issues are some of the
                          biggest causes of downtime. Be in the know, with
                          temperature, humidity and power monitoring to increase
                          uptime.
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                } */}
                {
                  <SwiperSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                      }}
                    >
                      {" "}
                      <div
                        className="home_industry_tile"
                        style={{
                          backgroundImage: `url(${require("../../assets/Home/muni_industry_tile_bg.png")})`
                        }}
                      >
                        <img
                          style={{ height: "4.375rem" }}
                          src={
                            require("../../assets/Home/muni_logo.svg").default
                          }
                        ></img>
                        <div className="home_industry_tile_title">
                          Municipality
                        </div>
                        <div className="home_industry_tile_desc">
                          Know when you have a power failure or when your back
                          up system failed. Avoid spills or low head pump
                          failures.{" "}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                }
                {
                  <SwiperSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                      }}
                    >
                      {" "}
                      <div
                        className="home_industry_tile"
                        style={{
                          backgroundImage: `url(${require("../../assets/Home/rental_industry_tile_bg.png")})`
                        }}
                      >
                        <img
                          style={{ height: "4.375rem" }}
                          src={
                            require("../../assets/Home/rental_logo.svg").default
                          }
                        ></img>
                        <div className="home_industry_tile_title">
                          Rental / asset tracking
                        </div>
                        <div className="home_industry_tile_desc">
                          Know when your equipment is started or stopped. Get a
                          notification when fuel is low. Lack of fuel shutdowns
                          can quickly become expensive.{" "}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                }
                {
                  <SwiperSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                      }}
                    >
                      {" "}
                      <div
                        className="home_industry_tile"
                        style={{
                          backgroundImage: `url(${require("../../assets/Home/cons_industry_tile_bg.png")})`
                        }}
                      >
                        <img
                          style={{ height: "4.375rem" }}
                          src={
                            require("../../assets/Home/cons_logo.svg").default
                          }
                        ></img>
                        <div className="home_industry_tile_title">
                          Construction
                        </div>
                        <div className="home_industry_tile_desc">
                          Using run hours, idle time and fuel consumption, track
                          your equipment utilization.{" "}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                }
                {
                  <SwiperSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                      }}
                    >
                      {" "}
                      <div
                        className="home_industry_tile"
                        style={{
                          backgroundImage: `url(${require("../../assets/Home/manu_industry_tile_bg.png")})`
                        }}
                      >
                        <img
                          style={{ height: "4.375rem" }}
                          src={
                            require("../../assets/Home/manu_logo.svg").default
                          }
                        ></img>
                        <div className="home_industry_tile_title">
                          Industrial manufacturing
                        </div>
                        <div className="home_industry_tile_desc">
                          Connect your operations and track your processes.{" "}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                }
                {
                  <SwiperSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                      }}
                    >
                      {" "}
                      <div
                        className="home_industry_tile"
                        style={{
                          backgroundImage: `url(${require("../../assets/Home/emission_industry_tile_bg.png")})`
                        }}
                      >
                        <img
                          style={{ height: "4.375rem" }}
                          src={
                            require("../../assets/Home/emmission_logo.svg")
                              .default
                          }
                        ></img>
                        <div className="home_industry_tile_title">
                          Emissions management
                        </div>
                        <div className="home_industry_tile_desc">
                          Maintain compliance. Abnormal emissions is a sign of
                          lost productivity.{" "}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                }
                {
                  <SwiperSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                      }}
                    >
                      {" "}
                      <div
                        className="home_industry_tile"
                        style={{
                          backgroundImage: `url(${require("../../assets/Home/telco_industry_tile_bg.png")})`
                        }}
                      >
                        <img
                          style={{ height: "4.375rem" }}
                          src={
                            require("../../assets/Home/telco_logo.svg").default
                          }
                        ></img>
                        <div
                          className="home_industry_tile_title"
                          style={{ fontSize: "2rem" }}
                        >
                          Telecommunications
                        </div>
                        <div className="home_industry_tile_desc">
                          Reduce infrastructure maintenance costs using remote
                          monitoring.{" "}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                }
                {
                  <SwiperSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%"
                      }}
                    >
                      {" "}
                      <div
                        className="home_industry_tile"
                        style={{
                          backgroundImage: `url(${require("../../assets/Home/agri_industry_tile_bg.png")})`
                        }}
                      >
                        <img
                          style={{ height: "4.375rem" }}
                          src={
                            require("../../assets/Home/muni_logo.svg").default
                          }
                        ></img>
                        <div className="home_industry_tile_title">
                          Agriculture
                        </div>
                        <div className="home_industry_tile_desc">
                          Know when you have a power failure or when your back
                          up system failed. Avoid spills or low head pump
                          failures.{" "}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                }
                {/* <div
                className="home_industry_tile"
                style={{
                  backgroundImage: `url(${require("../../assets/Home/emer_industry_tile_bg.png")})`
                }}
              >
                <img
                  style={{ height: "4.375rem" }}
                  src={require("../../assets/Home/emergency_logo.svg").default}
                ></img>
                <div className="home_industry_tile_title">
                  Emergency response &amp; disaster relief
                </div>
                <div className="home_industry_tile_desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  faucibus enim auctor magna rhoncus, in vulputate elit tempus.{" "}
                </div>
              </div> */}
              </Swiper>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(8,1fr)",
                  width: "100%"
                }}
              >
                {[0, 1, 2, 3, 4, 5, 6,7].map((item: any, index: any) => {
                  return (
                    <div
                      key={index}
                      style={{
                        borderTop:
                          mobileIndustryTileActive == item
                            ? "3px solid #ff6900"
                            : "3px solid rgba(91, 103, 112,0.5)"
                      }}
                    ></div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </section>
      <section style={{ background: "#E7E9EA" }}>
        <div className="home_flex_row">
          <div className="home_left_section ">
            <div className=" left_section_home">
              <div className="d-flex align-items-center home_margin_left">
                <img
                  style={{ width: "1.25rem", marginRight: "1rem" }}
                  src={require("../../assets/rulo_matrix.svg").default}
                ></img>
                <label className="home_left_sec_header">Benefits</label>
              </div>
              <div className="home_left_section_second_row">
                <div className="d-flex align-items-center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navTo("/benefits");
                    }}
                  >
                    <span className="left_section_sub_text_home">View all</span>
                    <img
                      style={{ height: "0.625rem" }}
                      src={
                        require("../../assets/Home/orange_arrow.svg").default
                      }
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="home_right_side home_benefits_right_side"
            style={{ flexGrow: 1 }}
          >
            <div style={{ position: "relative" }}>
              {smallScreen &&
                (benefitsActive[1].is_active ||
                  benefitsActive[2].is_active) && (
                  <div className="home_benefits_tab_header_shader left_shader"></div>
                )}
              {smallScreen &&
                (benefitsActive[1].is_active ||
                  benefitsActive[0].is_active) && (
                  <div className="home_benefits_tab_header_shader right_shader"></div>
                )}
              <div id={"benefitsTitleRow"} className="home_benefits_tab_row">
                <ReactInterval
                  timeout={3000}
                  enabled={benefitsTileOnClick}
                  callback={() => {
                    if (!smallScreen) {
                      if (benefitsActive[0].is_active) changeBenefitsTab(1);
                      else if (benefitsActive[1].is_active)
                        changeBenefitsTab(2);
                      else if (benefitsActive[2].is_active)
                        changeBenefitsTab(3);
                      else if (benefitsActive[3].is_active)
                        changeBenefitsTab(0);
                    }
                  }}
                />
                {benefitsActive.map((item: any, index: any) => (
                  <div
                    key={item.title}
                    id={`benefitsTitle${index}`}
                    className={`home_benefits_tab ${
                      item.is_active ? "home_benefits_tab_active" : ""
                    }`}
                    onClick={() => {
                      setBenefitsTileOnClick(false);
                      scrollHorizontalBenefitsTitleRow(index, "touch");
                    }}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
            {
              <div style={{ display: smallScreen ? "none" : "block" }}>
                {benefitsActive[0].is_active && (
                  <div className="home_benefits_content_tab">
                    <div className="home_benefits_tab_grid_item2 home_benefits_tab1_margin_right">
                      Detailed reports when you want them and easy-to-read
                      dashboards when you’re on the go.
                    </div>
                    <div className="home_benefits_tab_grid_item1 ">
                      <div style={{ width: "93.32%", marginTop: "2.1875rem" }}>
                        <div style={{ position: "relative" }}>
                          <img
                            className="home_benefits_tab1_bg"
                            src={
                              require("../../assets/Home/home_benefits_tab1_bg.svg")
                                .default
                            }
                          ></img>
                          <img
                            className="home_benefits_tab1_img"
                            src={require("../../assets/Home/home_benefits_tab1_img.png")}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {benefitsActive[1].is_active && (
                  <div className="home_benefits_content_tab">
                    <div className="home_benefits_tab_grid_item2 home_benefits_tab2_custom home_benefits_tab2_margin_right">
                      Cellular connectivity, like phone calls, sometimes gets
                      dropped. We designed our platform with real-world
                      connectivity in mind. That’s why all data and alarm logs
                      are stored on a flash drive until network connectivity is
                      available: so you can get your data. Consistently.
                    </div>
                    <div className="home_benefits_tab_grid_item1">
                      <div style={{ width: "100%", marginTop: "2.125rem" }}>
                        <div style={{ position: "relative" }}>
                          <img
                            className="home_benefits_tab1_bg"
                            src={require("../../assets/Home/home_benefits_tab2_bg.png")}
                          ></img>
                          <img
                            className="home_benefits_tab2_img"
                            src={
                              require("../../assets/Home/home_benefits_tab2_img.svg")
                                .default
                            }
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {benefitsActive[2].is_active && (
                  <div className="home_benefits_content_tab">
                    <div className="home_benefits_tab_grid_item2 home_benefits_tab3_margin_right">
                      Unlike conventional remote monitoring which polls every
                      fifteen minutes, inputs are polled or sampled by the Rulo
                      controller multiple times per second. This ensures you
                      receive reliable alarms and notifications without adding
                      to your data costs.
                    </div>
                    <div className="home_benefits_tab_grid_item1">
                      <div style={{ width: "89.45%" }}>
                        <div style={{ position: "relative" }}>
                          <img
                            className="home_benefits_tab1_bg"
                            src={require("../../assets/Home/home_benefits_tab3_bg.png")}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {benefitsActive[3].is_active && (
                  <div className="home_benefits_content_tab">
                    <div className="home_benefits_tab_grid_item2 home_benefits_tab3_margin_right">
                      Rulo uses NIST-recommended authentication and encryption
                      to give you a secure way to manage your assets.
                    </div>
                    <div className="home_benefits_tab_grid_item1">
                      <div style={{ marginTop: "2.1875rem", width: "69.45%" }}>
                        <div style={{ position: "relative" }}>
                          <img
                            className="home_benefits_tab1_bg"
                            style={{ width: "80%" }}
                            src={require("../../assets/Home/home_benefits_tab4_bg.png")}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }

            <div style={{ display: smallScreen ? "block" : "none" }}>
              <Swiper
                id="myBenefitsSwiper"
                modules={[
                  Navigation,
                  Autoplay,
                  Pagination,
                  Scrollbar,
                  A11y,
                  EffectFade,
                  EffectFlip
                ]}
                spaceBetween={50}
                style={{ width: "90vw" }}
                autoplay={{ delay: 6000 }}
                slidesPerView={"auto"}
                onSlideChange={e => {
                  scrollHorizontalBenefitsTitleRow(e.activeIndex, "callback");
                }}
                onSwiper={swiper => console.log(swiper)}
              >
                <SwiperSlide>
                  <div className="home_benefits_content_tab">
                    <div className="home_benefits_tab_grid_item2 home_benefits_tab1_margin_right">
                      Detailed reports when you want them and easy-to-read
                      dashboards when you’re on the go.
                    </div>
                    <div className="home_benefits_tab_grid_item1 ">
                      <div style={{ width: "93.32%", marginTop: "2.1875rem" }}>
                        <div style={{ position: "relative" }}>
                          <img
                            className="home_benefits_tab1_bg"
                            src={
                              require("../../assets/Home/home_benefits_tab1_bg.svg")
                                .default
                            }
                          ></img>
                          <img
                            className="home_benefits_tab1_img"
                            src={require("../../assets/Home/home_benefits_tab1_img.png")}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="home_benefits_content_tab">
                    <div className="home_benefits_tab_grid_item2 home_benefits_tab2_custom home_benefits_tab2_margin_right">
                      Cellular connectivity, like phone calls, sometimes gets
                      dropped. We designed our platform with real-world
                      connectivity in mind. That’s why all data and alarm logs
                      are stored on a flash drive until network connectivity is
                      available: so you can get your data. Consistently.
                    </div>
                    <div className="home_benefits_tab_grid_item1">
                      <div style={{ width: "100%", marginTop: "2.125rem" }}>
                        <div style={{ position: "relative" }}>
                          <img
                            className="home_benefits_tab1_bg"
                            src={require("../../assets/Home/home_benefits_tab2_bg.png")}
                          ></img>
                          <img
                            className="home_benefits_tab2_img"
                            src={
                              require("../../assets/Home/home_benefits_tab2_img.svg")
                                .default
                            }
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="home_benefits_content_tab"
                    style={{ width: "90vw" }}
                  >
                    <div className="home_benefits_tab_grid_item2 home_benefits_tab3_margin_right">
                      Unlike conventional remote monitoring which polls every
                      fifteen minutes, inputs are polled or sampled by the Rulo
                      controller multiple times per second. This ensures you
                      receive reliable alarms and notifications without adding
                      to your data costs.
                    </div>
                    <div className="home_benefits_tab_grid_item1">
                      <div style={{ width: "89.45%" }}>
                        <div style={{ position: "relative" }}>
                          <img
                            className="home_benefits_tab1_bg"
                            src={require("../../assets/Home/home_benefits_tab3_bg.png")}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="home_benefits_content_tab"
                    style={{ width: "90vw" }}
                  >
                    <div className="home_benefits_tab_grid_item2 home_benefits_tab3_margin_right">
                      Rulo uses NIST-recommended authentication and encryption
                      to give you a secure way to manage your assets.
                    </div>
                    <div className="home_benefits_tab_grid_item1">
                      <div style={{ width: "89.45%", marginTop: "2.125rem" }}>
                        <div style={{ position: "relative" }}>
                          <img
                            className="home_benefits_tab1_bg"
                            src={require("../../assets/Home/home_benefits_tab4_bg.png")}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: "#FFFFFF" }}>
        <div className="home_flex_row home_company_padding">
          <div className="home_left_section ">
            <div className=" left_section_home">
              <div className="d-flex align-items-center home_margin_left">
                <img
                  style={{ width: "1.25rem", marginRight: "1rem" }}
                  src={require("../../assets/rulo_matrix.svg").default}
                ></img>
                <label className="home_left_sec_header">Meet Rulo</label>
              </div>
              <div className="home_left_section_second_row">
                <div className="d-flex align-items-center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navTo("/company");
                    }}
                  >
                    <span className="left_section_sub_text_home">Company</span>
                    <img
                      style={{ height: "0.625rem" }}
                      src={
                        require("../../assets/Home/orange_arrow.svg").default
                      }
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home_right_side  home_company_width">
            <Swiper
              id="home_company_slider"
              modules={[
                Navigation,
                Autoplay,
                Pagination,
                Scrollbar,
                A11y,
                EffectFade,
                EffectFlip
              ]}
              onSwiper={() => {}}
              spaceBetween={50}
              slidesPerView={"auto"}
              autoplay={{ delay: 5000 }}
              onSlideChange={e => {
                setCompanyActive(e.activeIndex);
              }}
            >
              <SwiperSlide>
                <div id="tab0" className="home_company_grid ">
                  <div className="home_company_grid_item2">
                    <div>
                      <div className="home_company_tab_title">
                        Rulo is at the center{" "}
                        <span className="home_company_rulo_title">
                          of your operations.
                        </span>
                      </div>
                      <div className="home_company_sub_title">
                        The intersection of simplicity, security and cost.
                      </div>
                      <div className="home_company_tab_desc">
                        Named after Franz Reuleaux, the 19th-century German
                        engineer who advanced the study of machines, a reuleaux
                        triangle is a shape made with intersection curves when
                        circles are drawn from the vertices of an equilateral
                        triangle.
                      </div>
                    </div>
                  </div>
                  <div className="home_company_grid_item1">
                    <img
                      src={
                        require("../../assets/Home/home_company_tab1.svg")
                          .default
                      }
                    ></img>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div id="tab1" className="home_company_grid ">
                  <div className="home_company_grid_item2">
                    <div>
                      <div className="home_company_tab_title">
                        Operations simplified.
                      </div>
                      <div className="home_company_sub_title">
                        Detailed reports when you want them and easy-to-read
                        dashboards when you’re on the go. Rulo’s simple
                        installation, and user-friendly mobile interface gives
                        new meaning to “out of sight, out of mind.”
                      </div>
                    </div>
                  </div>
                  <div className="home_company_grid_item1">
                    <img
                      src={
                        require("../../assets/Home/home_company_tab2.svg")
                          .default
                      }
                    ></img>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div id="tab2" className="home_company_grid ">
                  <div className="home_company_grid_item2">
                    <div>
                      <div className="home_company_tab_title">
                        Rulo is the lowest cost solution to install and operate.
                      </div>
                      <div className="home_company_sub_title">
                        When equipment failes to produce at max capcity, it
                        costs you time and money, Rulo notifies you when
                        equipment activity falls below user-defined thresholds.
                      </div>
                    </div>
                  </div>
                  <div className="home_company_grid_item1">
                    <img
                      src={
                        require("../../assets/Home/home_company_tab3.svg")
                          .default
                      }
                    ></img>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div id="tab3" className="home_company_grid ">
                  <div className="home_company_grid_item2">
                    <div>
                      <div className="home_company_tab_title">
                        Never sacrifice security.
                      </div>
                      <div className="home_company_sub_title">
                        Protect your assets with banking grade security, Rulo
                        adheres to NIST standards for secure communication by
                        utilizing TLS 1.2 and 256-bit encryption.
                      </div>
                    </div>
                  </div>
                  <div className="home_company_grid_item1">
                    <img
                      src={
                        require("../../assets/Home/home_company_tab4.svg")
                          .default
                      }
                    ></img>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>

            <div className="home_company_dot_tabs">
              {[0, 1, 2, 3].map((item: any) => (
                <div
                  key={item}
                  onClick={() => {
                    onClickHomeCompanyDot(item);
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={
                      companyActive == item
                        ? `${
                            require("../../assets/Home/active_dot_tab.svg")
                              .default
                          }`
                        : `${
                            require("../../assets/Home/in_active_dot_tab.svg")
                              .default
                          }`
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: "#253746" }}>
        <div className="home_flex_row no_padding">
          <div className="home_left_section ">
            <div className=" left_section_home home_padding_top_5rem">
              <div className="d-flex align-items-center home_margin_left">
                <img
                  style={{ width: "1.25rem", marginRight: "1rem" }}
                  src={require("../../assets/rulo_matrix.svg").default}
                ></img>
                <label className="home_left_sec_header color-white">
                  Rulo blog
                </label>
              </div>
              <div className="home_left_section_second_row">
                <div className="d-flex align-items-center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navTo("/blog");
                    }}
                  >
                    <span className="left_section_sub_text_home color-white">
                      View All
                    </span>
                    <img
                      style={{ height: "0.625rem" }}
                      src={
                        require("../../assets/Home/orange_arrow.svg").default
                      }
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home_right_side  home_blog_right">
            <div style={{ position: "relative" }}>
              <div className="home_blog_right_side"></div>
              <div className="home_blog_text_box">
                <div className="home_blog_title">{blogData[0]?.heading}</div>
                <div className="home_blog_date">{blogData[0]?.date_time}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Home;
