export const companySize = ["1-10", "11-50", "50+"];
export const locationValue = ["US", "Canada", "Mexico"];

export const timeZoneValue = [
  { name: "Eastern Standard Time (ET): UTC-05:00", value: "ET" },
  { name: "Central Standard Time (CT): UTC-06:00", value: "CT" },
  { name: "Mountain Standard Time (MT): UTC-07:00", value: "MT" },
  { name: "Pacific Standard Time (PT): UTC-08:00", value: "PT" },
];

export const timeArray = [
  { name: "12:00 AM", value: "00:00" },
  { name: "12:30 AM", value: "00:30" },
  { name: "1:00 AM", value: "01:00" },
  { name: "1:30 AM", value: "01:30" },
  { name: "2:00 AM", value: "02:00" },
  { name: "2:30 AM", value: "02:30" },
  { name: "3:00 AM", value: "03:00" },
  { name: "3:30 AM", value: "03:30" },
  { name: "4:00 AM", value: "04:00" },
  { name: "4:30 AM", value: "04:30" },
  { name: "5:00 AM", value: "05:00" },
  { name: "5:30 AM", value: "05:30" },
  { name: "6:00 AM", value: "06:00" },
  { name: "6:30 AM", value: "06:30" },
  { name: "7:00 AM", value: "07:00" },
  { name: "7:30 AM", value: "07:30" },
  { name: "8:00 AM", value: "08:00" },
  { name: "8:30 AM", value: "08:30" },
  { name: "9:00 AM", value: "09:00" },
  { name: "9:30 AM", value: "09:30" },
  { name: "10:00 AM", value: "10:00" },
  { name: "10:30 AM", value: "10:30" },
  { name: "11:00 AM", value: "11:00" },
  { name: "11:30 AM", value: "11:30" },
  { name: "12:00 PM", value: "12:00" },
  { name: "12:30 PM", value: "12:30" },
  { name: "1:00 PM", value: "13:00" },
  { name: "1:30 PM", value: "13:30" },
  { name: "2:00 PM", value: "14:00" },
  { name: "2:30 PM", value: "14:30" },
  { name: "3:00 PM", value: "15:00" },
  { name: "3:30 PM", value: "15:30" },
  { name: "4:00 PM", value: "16:00" },
  { name: "4:30 PM", value: "16:30" },
  { name: "5:00 PM", value: "17:00" },
  { name: "5:30 PM", value: "17:30" },
  { name: "6:00 PM", value: "18:00" },
  { name: "6:30 PM", value: "18:30" },
  { name: "7:00 PM", value: "19:00" },
  { name: "7:30 PM", value: "19:30" },
  { name: "8:00 PM", value: "20:00" },
  { name: "8:30 PM", value: "20:30" },
  { name: "9:00 PM", value: "21:00" },
  { name: "9:30 PM", value: "21:30" },
  { name: "10:00 PM", value: "22:00" },
  { name: "10:30 PM", value: "22:30" },
  { name: "11:00 PM", value: "23:00" },
  { name: "11:30 PM", value: "23:30" },
];

export const moniterArray = [
  "Motors, pumps",
  "Engines, generators, compressors",
  "Variable frequency drives, PLCs, HMIs",
  "Tank levels",
  "Pressures, temperatures, flow measurement, hour meter",
  "Rental skids, process equipment",
  "Other stationary or non-road mobile equipment",
];

export const solveArray = [
  "Unplanned downtime due to emergency breakdowns",
  " Unable to get warning or shutdown codes from the equipment remotely",
  "Simple issues that can be avoided, like out of fuel or loss of pressure",
  "Help identify when equipment is being used or if it is being misused",
  "Know the location of equipment",
  "Waiting for the SCADA vendor to activate service or perform updates",
  "Unreliable data (lot of 0s) or false alarms",
  "SCADA is expensive",
  "Need better remote monitoring product support",
  "Would prefer a custom branded product",
];
