import React from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import rulo_logo from "../../assets/rulo_logo.svg";
import button_arrow from "../../assets/button_arrow.svg";
import hamburger_icon from "../../assets/hamburger_icon.svg";
import close_icon from "../../assets/close_icon.svg";
import "../../App.css";

class Header extends React.Component<any, any> {
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    this.setState({ screen_width: window.innerWidth });
    this.setState({ is_small_screen: window.innerWidth <= 850 });
    this.setState({
      is_open: window.innerWidth <= 850 ? this.state.is_open : false
    });
  }
  constructor(props: any) {
    super(props);
    this.state = {
      screen_width: 0,
      is_small_screen: false,
      is_open: false,
      is_active: 0
    };
  }
  toggle_menu() {
    this.setState({ is_open: !this.state.is_open });
  }
  toggle_menu_home(){
    if(this.state.is_open){
      this.setState({ is_open: !this.state.is_open })
    }
  }

  render() {
    return (
      <div>
        <nav>
          <NavLink
            to="/"
            style={{ textDecoration: "none" }}
            onClick={() => {
              this.setState({ is_active: 0 });
              this.toggle_menu_home()
            }}
          >
            <img className="rulo_logo" src={rulo_logo} alt="rulo logo"></img>
          </NavLink>
          {!this.state.is_small_screen ? (
            <div
              style={{ flexGrow: 1 }}
              className="flex_row justify-content-between text_nav"
            >
              <div className="flex_row" style={{ gap: "1.5625rem" }}>
                <NavLink
                  to="/platform"
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? " left_item active_tab" : "left_item"
                  }
                  onClick={() => {
                    this.setState({ is_active: 1 });
                  }}
                >
                  {/* <div className={`left_item ${this.state.is_active==1?'active_tab':''}`}>Platform</div> */}
                  Platform
                </NavLink>
                <NavLink
                  to="/industry"
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? " left_item active_tab" : "left_item"
                  }
                  onClick={() => {
                    this.setState({ is_active: 2 });
                  }}
                >
                  Industries
                </NavLink>
                <NavLink
                  to="/benefits"
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? " left_item active_tab" : "left_item"
                  }
                  onClick={() => {
                    this.setState({ is_active: 3 });
                  }}
                >
                  Benefits
                </NavLink>
              </div>

              <div
                className="flex_row justify-content-center"
                style={{ gap: "1.25rem" }}
              >
                <NavLink
                  to="/company"
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? " right_item active_tab" : "right_item"
                  }
                  onClick={() => {
                    this.setState({ is_active: 4 });
                  }}
                >
                  Company
                </NavLink>
                <NavLink
                  to="/blog"
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? " right_item active_tab" : "right_item"
                  }
                  onClick={() => {
                    this.setState({ is_active: 5 });
                  }}
                >
                  Blog
                </NavLink>
                <NavLink
                  to="/contact"
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? " right_item active_tab" : "right_item"
                  }
                  onClick={() => {
                    this.setState({ is_active: 6 });
                  }}
                >
                  Contact
                </NavLink>
               
                <button
                  style={{ marginLeft: "0.625rem" }}
                  className="orange-button"
                  onClick={() => {
                    window.open("https://www.aurum.rulotech.com/", "_blank");
                  }}
                >
                  <div
                    className="flex_row justify-content-center"
                    style={{ gap: "0.5em" }}
                  >
                    {/* <div>
                      <img style={{ width: "1rem" }} src={button_arrow}></img>
                    </div> */}
                    <div style={{ height: "fit-content" }}>Login</div>
                  </div>
                </button>
                <NavLink
                  to="/asset-management"
                  style={{ textDecoration: "none" }}
                  className={({ isActive }) =>
                    isActive ? " right_item active_tab check_button" : "right_item check_button"
                  }
                  onClick={() => {
                    this.setState({ is_active: 6 });
                  }}
                >
                  Check Pricing
                </NavLink>
              </div>
            </div>
          ) : (
            <div
              style={{ flexGrow: 1 }}
              className="hamburger d-flex justify-content-end"
            >
              <img
                onClick={() => this.toggle_menu()}
                src={close_icon }
                alt="hamburger  icon"
                style={{padding:!this.state.is_open?'0.84375rem 0px':'0',display:!this.state.is_open?'none':'block'}}
              ></img>
              <img
                onClick={() => this.toggle_menu()}
                src={hamburger_icon}
                alt="hamburger  icon"
                style={{padding:'0.84375rem 0px',display:!this.state.is_open?'block':'none' }}
              ></img>
            </div>
          )}
        </nav>
        {
          <div
            style={{ maxHeight: this.state.is_open ? "24.3152rem" : "0px" }}
            className="flex_column ham-menu"
          >
            <NavLink to="/platform" className="ham-menu-item">
              <span onClick={() => this.toggle_menu()}>Platform</span>
            </NavLink>
            <NavLink to="/industry" className="ham-menu-item">
              <span onClick={() => this.toggle_menu()}>Industries</span>
            </NavLink>
            <NavLink to="/benefits" className="ham-menu-item">
              <span onClick={() => this.toggle_menu()}>Benefits</span>
            </NavLink>
            <NavLink to="/company" className="ham-menu-item">
              <span onClick={() => this.toggle_menu()}>Company</span>
            </NavLink>
            <NavLink to="/blog" className="ham-menu-item">
              <span onClick={() => this.toggle_menu()}>Blog</span>
            </NavLink>
            <NavLink to="/contact" className="ham-menu-item">
              <span onClick={() => this.toggle_menu()}>Contact</span>
            </NavLink>
            <a
              href="https://www.aurum.rulotech.com/"
              target="_blank"
              className="ham-menu-item"
            >
              <span onClick={() => this.toggle_menu()}>Login</span>
            </a>
            <NavLink to="/asset-management" className="ham-menu-item">
              <span onClick={() => this.toggle_menu()}>Check Pricing</span>
            </NavLink>
          </div>
        }
      </div>
    );
  }
}
export default Header;
