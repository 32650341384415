import "../../App.css";
import React, { useEffect, useState } from "react";
import "./Footer.css";
import rulo_matrix from "../../assets/rulo_matrix.svg";
import rulo_footer from "../../assets/rulo_footer_logo.svg";
import button_arrow from "../../assets/button_arrow.svg";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname: string) => navigate(`${pathname}`);
};

const useNavigateSearchSpecificDiv = () => {
  const navigate = useNavigate();
  return (pathname: string, id: string) =>
    navigate(`${pathname}`, { state: id });
};

let currentIsContactPage = false;
const Footer = () => {
  const navigateSearch = useNavigateSearch();
  /* for navigating to particular page */
  const navTo = (path: string) => {
    window.scrollTo(0, 0);
    navigateSearch(path);
  };

  /* Added a separate Navigation function to go to specific div of another page */
  const navigateSearchDiv = useNavigateSearchSpecificDiv();
  const navToSpecificDiv = (path: string, id: string) => {
    navigateSearchDiv(path, id);
  };

  const [isContactPage, setIsContactPage] = useState<boolean>(false);
  let location = useLocation();
  useEffect(() => {
    let matchBool = /contact/.test(location.pathname);
    if (matchBool && !currentIsContactPage) {
      setIsContactPage(true);
      currentIsContactPage = !currentIsContactPage;
    } else if (!matchBool && currentIsContactPage) {
      setIsContactPage(false);
      currentIsContactPage = !currentIsContactPage;
    }
  }, [location]);
  return (
    <div>
      {!isContactPage && (
        <div className="flex_row bg-color-blue">
          <div className="left_section ">
            <div className="pl-28per ">
              <div style={{display:'flex'}}>
              <img style={{ width: "1.25rem", marginRight: "1rem" }} src={rulo_matrix}></img>
              <label className="contact">Contact</label>
              </div>
            </div>
          </div>
          <div className="right_section" style={{ flexGrow: 1 }}>
            <div className="right_section_text">
              Ready to simplify your operations?
            </div>
            <button
              className="orange-button pd-6em"
              onClick={() => {
                navTo("/contact");
              }}
            >
              <div
                className="flex_row justify-content-center"
                style={{ gap: "0.5em" }}
              >
                <div>
                  <img style={{ width: "1rem" }} src={button_arrow}></img>
                </div>
                <div style={{ height: "fit-content" }}>Request a demo unit</div>
              </div>
            </button>
          </div>
        </div>
      )}
      <div className="flex_row footerTopograpy justify-content-between">
        <div className="flex_row mycol">
          <div className="footer-item1">
            <span
              onClick={() => {
                navToSpecificDiv("/links", "link1");
              }}
              style={{ cursor: "pointer" }}
            >
              Privacy policy
            </span>{" "}
            |
            <span
              onClick={() => {
                navToSpecificDiv("/links", "link2");
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Terms of use
            </span>{" "}
            |
            <span
              onClick={() => {
                window.open("https://www.aurum.rulotech.com/", "_blank");
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Customer login
            </span>
          </div>
          <div className="footer-item2">
          © 2024 Rulo. All rights reserved.
          </div>
        </div>
        <div className="footer-item3">
          <img src={rulo_footer}></img>
        </div>
      </div>
    </div>
  );
};
export default Footer;
