import React, { useEffect } from "react";
import Platform from "./components/Platform/Platform";
import Industry from "./components/Industry/Industry";
import Home from "./components/Home/Home";
import Header from "./components/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Benefits from "./components/Benefits/Benefits";
import Company from "./components/Company/Company";
import Blog from "./components/Blog/Blog";
import Contact from "./components/Contact/Contact";
import AdditionalLinks from "./components/AdditionalLinks/AdditionalLinks";
import Blogpost from "./components/BlogPost/Blogpost";
import AssetManagement from "./components/AssetManagement/AssetManagement";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/industry" element={<Industry />} />
        <Route path="/benefits" element={<Benefits />} />
        <Route path="/company" element={<Company />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/links" element={<AdditionalLinks />} />

        <Route path="/asset-management" element={<AssetManagement />} />

        <Route path="/blog/:id" element={<Blogpost />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
