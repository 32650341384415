import React, { useState, useCallback, useEffect } from "react";
import rulo_matrix from "../../assets/rulo_matrix.svg";
import "./Contact.css";
import { useForm } from "react-hook-form";
import postContactDetails from "../../service/contactService";
import rulo_logo from "../../assets/rulo_logo.svg";

/* interface added for storing the form data */
export interface RequiredContactDetails {
  interest: any;
  details: any;
  industry: any;
  first_name: string;
  last_name: string;
  email: string;
  phone_no: string;
  company: string;
}

const Contact = () => {
  /* Added the list of industries for industry dropdown */
  const industries = [
    { name: "Oil / gas", value: "Oil / gas" },
    { name: "Crypto mining", value: "Crypto mining" },
    { name: "Muncipality", value: "Muncipality" },
    { name: "Rental / asset tracking", value: "Rental / asset tracking" },
    { name: "Construction", value: "Construction" },
    { name: "Industrial manufacturing", value: "Industrial manufacturing" },
    { name: "Emissions management", value: "Emissions management" },
    { name: "Telecommunications", value: "Telecommunications" },
    {
      name: "Emergency response & disaster relief",
      value: "Emergency response & disaster relief"
    },
    { name: "Other", value: "Other" }
  ];
  const { register, handleSubmit, reset } = useForm<RequiredContactDetails>();
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  /* Function added for storing the form data */
  const saveFormData = ({
    interest,
    details,
    industry,
    first_name,
    last_name,
    email,
    phone_no,
    company
  }: RequiredContactDetails) => {
    let contactDetails = {
      interest: interest,
      details: details,
      application: industry,
      first_name: first_name,
      last_name: last_name,
      email_id: email,
      phone_number: phone_no,
      company: company
    };
    postContactDetails(contactDetails).then((data: any) => {
      setShowSuccessMsg(true);
    });
    reset();
  };

  useEffect(() => {
    document.title = "Rulo | Contact";
  }, []);

  return (
    <div>
      {/* Contact Banner Section */}
      <section className="contact_banner">
        <div className="contact_text">
          <img src={rulo_matrix}></img>
          <label className="contact_title">Contact</label>
          <div className="contact_heading">How can we help?</div>
        </div>
      </section>
      {/* Contact Form Section */}
      <section className="contact_body rolo-body-main">
        <div className="contact_grid rolo-container">
          {/* Form Section */}
          <div className="contact_grid_item1">
            <form onSubmit={handleSubmit(saveFormData)} className="width_100">
              {/* Form Interest Section */}
              <section className="form_headings">
                Interest
                <div className="form_sections pt1_625 width_100">
                  <label className="form_labels">
                    What is the product or service you are interested in?
                  </label>
                  <textarea
                    rows={8}
                    {...register("interest")}
                    className="form_inputs"
                  ></textarea>
                </div>
              </section>
              {/* Form Details Section */}
              <section className="form_headings pt2">
                Details
                <div className="display_flex">
                  <div className="form_sections pt1_375">
                    <label className="form_labels">
                      Briefly describe your application
                    </label>
                    <textarea
                      rows={8}
                      {...register("details")}
                      className="form_inputs"
                    ></textarea>
                  </div>
                  <div className="form_sections pt1_375">
                    <label className="form_labels">Industry</label>
                    <select className="form_inputs" {...register("industry")}>
                      {industries.map(
                        (industry: { name: string; value: string }) => {
                          return (
                            <option key={industry.value} value={industry.value}>
                              {industry.name}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
              </section>
              {/* Form Contact Section */}
              <section className="form_headings pt2">
                Contact
                <div className="display_flex">
                  <div className="form_sections pt1_375">
                    <label className="form_labels">First Name</label>
                    <input
                      type="text"
                      className="form_inputs"
                      {...register("first_name")}
                      required
                    ></input>
                  </div>
                  <div className="form_sections pt1_375">
                    <label className="form_labels">Last Name</label>
                    <input
                      type="text"
                      className="form_inputs"
                      {...register("last_name")}
                      required
                    ></input>
                  </div>
                </div>
                <div className="display_flex">
                  <div className="form_sections pt1_625">
                    <label className="form_labels">Email Address</label>
                    <input
                      type="email"
                      className="form_inputs"
                      {...register("email")}
                      required
                    ></input>
                  </div>
                  <div className="form_sections pt1_625">
                    <label className="form_labels">Phone Number</label>
                    <input
                      type="tel"
                      className="form_inputs"
                      {...register("phone_no")}
                      pattern="[0-9]{10}"
                      required
                    ></input>
                  </div>
                </div>
                <div className="display_flex">
                  <div className="form_sections pt1_625">
                    <label className="form_labels">Company</label>
                    <input
                      type="text"
                      className="form_inputs"
                      {...register("company")}
                      required
                    ></input>
                  </div>
                </div>
              </section>
              {/* Submit button */}
              <div className="pt_4_75">
                <button className="btn_submit">
                  <div className="submit_txt">Submit</div>
                </button>
              </div>
              {/* Showing the Success message */}
              {showSuccessMsg ? (
                <div className="pt_1_37">
                  <div className="success_msg">
                    Thanks! Your message has been sent.
                  </div>
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
          {/* Blue box Section */}
          <div className="contact_grid_item2">
            <div className="contact_right_box">
              <div className="contact_right_heading">
                Risk free 30-day trial
              </div>
              <div className="contact_right_subheading">
                We’ll send you a module and you can try it out for 30 days. If
                it’s not for you, send it back. Keep it and we’ll charge your
                card and start your subscription*
              </div>
              <div className="contact_right_terms">
                *Terms and conditions apply
              </div>
              {/* <div>
                <button className="orange-button demo_btn_pd">
                  <div className="flex_row justify-content-center" style={{ gap: "0.6925em" }} >
                    <div >
                      <img style={{ width: "1rem" }} src={require('../../assets/button_arrow.svg').default}></img>
                    </div>
                    <div style={{ height: "fit-content", color: 'white' }}>
                      Request a demo unit
                    </div>
                  </div>
                </button>
              </div> */}
            </div>
            <div className="contact_right_box rolo-contact-info">
              <div className="rolo-logo-contact">
                <img src={rulo_logo} alt="logo" />
                <p>
                    15425 N. Freeway, Suite 350
                    Houston, TX 77090
                </p>
              </div>
              <div className="rolo-contact-info-inner">
                <ul>
                  <li>
                    <h3>Technical support</h3>
                    <a href="mailto:info@rulotech.com">info@rulotech.com</a>
                  </li>
                  <li>
                    <h3>Sales</h3>
                    <a href="mailto:sales@rulotech.com">sales@rulotech.com</a>
                  </li>
                  <li>
                    <h3>Or call us</h3>
                    <a href="tel:+1 (281) 949-8989">+1 (281) 949-8989</a>
                  </li>
                </ul>
              </div>
             
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Contact;
