import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import moment from "moment";
import {
  companySize,
  locationValue,
  timeArray,
  timeZoneValue,
} from "./constant";


const dateFormatter = (date: any) => {
  if (date) {
    let newDate = moment(date).format("dddd, DD MMM, YYYY");
    return newDate;
  }
  return "";
};

const UserForm = ({
  step,
  formData,
  validation,
  handleValueChange,
}: {
  step: number;
  formData: any;
  validation: any;
  handleValueChange: (name: string, value: any) => void;
}) => {
  const [isLocation, setIsLocation] = useState(false);

  const handleChange = (event: any) => {
    const { name, value, checked } = event.target;

    if (checked && name === "location") {
      setIsLocation(false);
    }

    handleValueChange(name, value);
  };

  const handleOtherChange = (event: any) => {
    const { name } = event.target;
    setIsLocation(true);
    handleValueChange(name, "");
  };

  const handlePhoneChange = (value: any) => {
    handleValueChange("phone_number", value);
  };

  const handleDateTimeChange = (date: any, type: string) => {
    if (type === "appointment_date") {
      const newdate = date.format("YYYY-MM-DD");
      handleValueChange(type, newdate);
    } else if (type === "appointment_time") {
    }
  };

  return (
    <>
      {step === 1 && (
        <div className="rolo-asset-form-block  how-big-your-company ">
          <h3 className="rolo-inner-form-title">How many people are in your company?</h3>
          <div className="rolo-radio-groups">
            {companySize.map((size: string) => (
              <div className="rolo-radio-fild" key={size}>
                <input
                  type="radio"
                  name="company_size"
                  id={`size_${size}`}
                  value={size}
                  checked={formData?.company_size === size}
                  onChange={handleChange}
                />
                <label htmlFor={`size_${size}`}>{size}</label>
              </div>
            ))}
          </div>
          <p className="form_error">{validation.company_size}</p>
        </div>
      )}

      {step === 2 && (
        <div className="rolo-asset-form-block you-located">
          <h3 className="rolo-inner-form-title">Where are you located?</h3>
          <div className="rolo-radio-groups">
            {locationValue.map((location: string) => (
              <div className="rolo-radio-fild" key={location}>
                <input
                  type="radio"
                  name="location"
                  id={`location_${location}`}
                  value={location}
                  checked={formData.location === location}
                  onChange={handleChange}
                />
                <label htmlFor={`location_${location}`}>{location}</label>
              </div>
            ))}

            <div className="rolo-radio-fild">
              <input
                type="radio"
                name="location"
                id={`location_Other`}
                value="Other"
                checked={isLocation}
                onChange={handleOtherChange}
              />
              <label htmlFor={`location_Other`}>Other</label>
            </div>
            {isLocation && (
              <div className="rolo-text-field">
                <textarea
                  placeholder="Specify if other"
                  name="location"
                  onChange={handleChange}
                  value={formData.location}
                ></textarea>
              </div>
            )}
          </div>
          <p className="form_error">{validation.location}</p>
        </div>
      )}

      {step === 3 && (
        <div className="rolo-asset-form-block name-your-company">
          <h3 className="rolo-inner-form-title">
            What is the name of your company?
          </h3>
          <div className="rolo-radio-groups">
            <div className="rolo-text-field">
              <textarea
                placeholder="Enter your company name here"
                name="company"
                value={formData.company}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <p className="form_error">{validation.company}</p>
        </div>
      )}

      {step === 4 && (
        <div className="rolo-asset-form-block your-name">
          <h3 className="rolo-inner-form-title">What is your name?</h3>
          <div className="rolo-radio-groups">
            <div className="rolo-form-row">
              <div className="rolo-input-field">
                <label>First Name</label>
                <input
                  className="rolo-input"
                  type="text"
                  placeholder="John"
                  name="first_name"
                  id="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                />
                <p className="form_error">{validation.first_name}</p>
              </div>
              <div className="rolo-input-field">
                <label>Last Name</label>
                <input
                  className="rolo-input"
                  type="text"
                  placeholder="Doe"
                  name="last_name"
                  id="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                />
                <p className="form_error">{validation.last_name}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 5 && (
        <div className="rolo-asset-form-block your-email">
          <h3 className="rolo-inner-form-title">What is your email?</h3>
          <div className="rolo-radio-groups">
            <div className="rolo-input-field">
              <input
                className="rolo-input"
                type="email"
                placeholder="Enter your email here"
                name="email_id"
                id="email_id"
                value={formData.email_id}
                onChange={handleChange}
              />
            </div>
            <p className="form_error">{validation.email_id}</p>

            <h3 className="rolo-inner-form-title rolo-inner-title">
              And what is your phone number?
            </h3>
            <PhoneInput
              international
              placeholder="Enter phone number"
              initialValueFormat="national"
              value={formData.phone_number}
              onChange={(e: any) => handlePhoneChange(e)}
            />
            <p className="form_error">{validation.phone_number}</p>

            <h3 className="rolo-inner-form-title rolo-inner-title">
              We greatly value your time. And we will not contact you, unless
              you want us to.
            </h3>
          </div>
        </div>
      )}

      {(step === 6 || step === 7) && (
        <div className="rolo-asset-form-block good-time-to-call-you">
          <h3 className="rolo-inner-form-title">
            What is a good time for us to call you?
          </h3>
          <div className="rolo-radio-groups">
            <div className="rolo-input-field">
              <label>Select Time zone</label>

              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={formData.time_zone}
                  name="time_zone"
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Select time zone</MenuItem>
                  {timeZoneValue.map((timezone: any) => (
                    <MenuItem value={timezone.value} key={timezone.value}>
                      {timezone.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p className="form_error">{validation.time_zone}</p>
            </div>
            <div className="rolo-form-row rolo-date-time">
              <div className="rolo-input-field">
                <label>Select Date</label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slots={{
                      textField: (params) => (
                        <TextField
                          {...params}
                          value={dateFormatter(formData.appointment_date)}
                        />
                      ),
                    }}
                    onChange={(e) =>
                      handleDateTimeChange(e, "appointment_date")
                    }
                  />
                </LocalizationProvider>
                <p className="form_error">{validation.appointment_date}</p>
              </div>
              <div className="rolo-input-field">
                <label>Select Time</label>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={formData.appointment_time}
                    onChange={handleChange}
                    name="appointment_time"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Select time</MenuItem>
                    {timeArray.map((time) => (
                      <MenuItem value={time.value} key={time.value}>
                        {time.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <p className="form_error">{validation.appointment_time}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserForm;
