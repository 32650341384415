import React, { useState, useCallback, useEffect } from "react";
import * as ReactDOM from "react-dom";
import "./Benefits.css";
import ReactInterval from "react-interval";
function getWindowDimensions() {
  if (window.innerWidth < 850) {
    return true;
  }
  return false;
}
class Benefits extends React.Component<any, any> {
  section_array: any[] = [];
  setIntervalRunTime: any = 0;

  componentDidMount() {
    document.title = "Benefits | Rulo";
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    // setInterval(() => {
    //   this.setIntervalRunTime++;

    //   this.setMaxHeigth();
    // }, 1000);
  }

  // scrollLog = () => {
  //   let currentPos = Number(document.getElementById("outerDiv")?.scrollTop);
  //   let divHeight = Number(document.getElementById("outerDiv")?.clientHeight);
  //   for (let i = 0; i < this.section_array.length; i++) {
  //     let item = this.section_array[i];
  //     if (currentPos < item) {
  //       if (currentPos + divHeight / 2 > item) {
  //         if (this.state.benefitsScrollSection != i + 1) {
  //           this.setState({ benefitsScrollSection: i + 1 });
  //         }
  //         return;
  //       } else {
  //         if (this.state.benefitsScrollSection != i) {
  //           this.setState({ benefitsScrollSection: i });
  //         }
  //         return;
  //       }
  //     }
  //   }
  // };
  // setMaxHeigth() {
  //   if (this.setIntervalRunTime > 5) {
  //     for (let i = 0; i < 100; i++) {
  //       window.clearInterval(i);
  //     }
  //   }
  //   let sum = 0;
  //   this.section_array = [];
  //   this.section_array.push(0);
  //   let maxClientHeight = 0;
  //   [1, 2, 3].forEach((item: any) => {
  //     let obj = document.getElementById(`sec${item}`);
  //     sum += Number(obj?.clientHeight);
  //     if (maxClientHeight < Number(obj?.clientHeight)) {
  //       maxClientHeight = Number(obj?.clientHeight);
  //     }
  //     this.section_array.push(sum);
  //   });
  //   if (this.state.is_small_screen) {
  //     let mydiv: HTMLElement | null = document.getElementById("outerDiv");
  //     if (mydiv != null) {
  //       mydiv.style.maxHeight = "";
  //       mydiv.style.minHeight = "auto";
  //       mydiv.style.height = "auto";
  //     }
  //     return;
  //   } else {
  //     let mydiv: HTMLElement | null = document.getElementById("outerDiv");
  //     if (mydiv != null) {
  //       mydiv.style.maxHeight = `${maxClientHeight}px`;
  //     }
  //   }
  // }
  resize() {
    this.setState({
      is_small_screen: window.innerWidth <= 850,
      benefitsScrollSection: 1
    });
    // this.setMaxHeigth();
  }
  // scrollTo(id: any) {
  //   // let obj = document.getElementById(id);
  //   //obj?.scrollIntoView();
  //   let mydiv: HTMLElement | null = document.getElementById("outerDiv");
  //   if (mydiv != null) {
  //     mydiv.scrollTo({top:this.section_array[id-1],behavior:'smooth'})
  //     // mydiv.style.minHeight = "auto";
  //     // mydiv.style.height = "auto";
  //   }

  // }
  scrollTo(id: any) {
    this.setState({ benefitsScrollSection: id });
  }
  constructor(props: any) {
    super(props);
    this.state = {
      is_small_screen: false,
      benefitsScrollSection: 1,
      activeTab: 1,
      benefitsTileOnClick: true
    };
    // this.scrollLog = this.scrollLog.bind(this);
    // this.setMaxHeigth = this.setMaxHeigth.bind(this);
  }

  render() {
    return (
      <div>
        <section className="benefits_banner">
          <div className="benefits_banner_title">
            Measurable solutions across{" "}
            <span>multiple industry verticals </span>
          </div>
          <div className="benefits_banner_desc">
            Regardless of industry, RULO’s platform and devices have you
            covered. We’re the most competitively priced equipment management
            solution on the market.
          </div>
        </section>
        <section style={{ background: "#FFFFFF" }}>
          <div className="benefits_flex_row">
            <div className="benefits_left_section ">
              <div className=" left_section_benefits">
                <div className="d-flex align-items-center">
                  <img
                    style={{ width: "1rem", marginRight: "1.3em" }}
                    src={require("../../assets/rulo_matrix.svg").default}
                  ></img>
                  <label className="benefits_left_sec_header">Benefits</label>
                </div>
                <div className="benefits_left_section_second_row benefits_display_none">
                  <div className=" align-items-center">
                    <div>
                      <div
                        onClick={() => {
                          this.setState({ benefitsTileOnClick: false });
                          this.scrollTo(1);
                        }}
                        className={`left_section_sub_text_benefits ${
                          this.state.benefitsScrollSection == 1
                            ? "selected_section"
                            : ""
                        }`}
                      >
                        Custom reports
                      </div>
                    </div>
                    <div>
                      <div
                        onClick={() => {
                          this.setState({ benefitsTileOnClick: false });
                          this.scrollTo(2);
                        }}
                        className={`left_section_sub_text_benefits ${
                          this.state.benefitsScrollSection == 2
                            ? "selected_section"
                            : ""
                        }`}
                      >
                        Consistent logs
                      </div>
                    </div>
                    <div>
                      <div
                        onClick={() => {
                          this.setState({ benefitsTileOnClick: false });
                          this.scrollTo(3);
                        }}
                        className={`left_section_sub_text_benefits ${
                          this.state.benefitsScrollSection == 3
                            ? "selected_section"
                            : ""
                        }`}
                      >
                        Reliable edge-generated events
                      </div>
                    </div>
                    <div>
                      <div
                        onClick={() => {
                          this.setState({ benefitsTileOnClick: false });
                          this.scrollTo(4);
                        }}
                        className={`left_section_sub_text_benefits ${
                          this.state.benefitsScrollSection == 4
                            ? "selected_section"
                            : ""
                        }`}
                      >
                        Zero-trust security
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!this.state.is_small_screen && (
              <div className=" benefits_right_side benefits_parent">
                <ReactInterval
                  timeout={3000}
                  enabled={this.state.benefitsTileOnClick}
                  callback={() => {
                    if (this.state.benefitsScrollSection < 4)
                      this.scrollTo(this.state.benefitsScrollSection + 1);
                    else this.scrollTo(1);
                  }}
                />
                {this.state.benefitsScrollSection == 1 && (
                  <section id="sec1" className="benefits_grid">
                    <div className="benefits_text_content benefits_grid_item_2 d-flex align-items-center">
                      <div>
                        <div
                          className="benefits_text_title"
                          style={{ marginBottom: "1.5rem" }}
                        >
                          Custom <span>reports</span>
                        </div>
                        <div
                          className="benefits_text_sub_title"
                          style={{ marginBottom: "1.5rem" }}
                        >
                          Detailed reports when you want them and easy-to-read
                          dashboards when you’re on the go.
                        </div>
                        <div className="benefits_text_desc">
                          Rulo’s fully integrated end to end solution let's you
                          create custom reports. Whether you want a dashboard
                          all your assets or compare data from multiple assets,
                          the reports give you full flexibility.
                        </div>
                      </div>
                    </div>
                    <div className="benefits_grid_item_1">
                      <div className="benefits_section1_outerdiv">
                        <img
                          style={{ width: "100%" }}
                          src={
                            this.state.is_small_screen
                              ? require("../../assets/Benefits/benefits_section1_phone_img.png")
                              : require("../../assets/Benefits/benefits_section1_bg.svg")
                                  .default
                          }
                        ></img>
                        <img
                          className="benefits_section1_overlay_img"
                          src={
                            this.state.is_small_screen
                              ? ""
                              : require("../../assets/Benefits/benefits_section1_ipad_screen.png")
                          }
                        ></img>
                      </div>
                    </div>
                  </section>
                )}
                {this.state.benefitsScrollSection == 2 && (
                  <section id="sec2" className="benefits_grid_reverse">
                    <div className="d-flex justify-content-end">
                      <div className="benefits_text_content benefits_grid_item_2 benefits_section2_text_margins d-flex align-items-center">
                        <div>
                          <div
                            className="benefits_text_title benefits_section2_title"
                            style={{ marginBottom: "1.1875rem" }}
                          >
                            Flash storage and forward
                          </div>
                          <div
                            className="benefits_text_sub_title"
                            style={{ marginBottom: "0.75rem" }}
                          >
                            We understand that cellular connectivity, like phone
                            calls, sometimes get dropped. Our platform was
                            designed with real-world connectivity in mind. All
                            data and alarm logs are stored on a flash drive
                            until network connectivity is available. So, you can
                            get your data. Consistently.
                          </div>
                          <div className="benefits_text_desc">
                            Poor network connectivity is not a unique problem to
                            you. Rulo has the solution.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="benefits_grid_item_1">
                      <div className="benefits_section2_outerdiv">
                        <img
                          className="benefits_section2_img"
                          src={require("../../assets/Benefits/benefits_section2_img.png")}
                        ></img>
                        <img
                          className="benefits_section2_overlay_img"
                          src={
                            require("../../assets/Benefits/benefits_section2_graph.svg")
                              .default
                          }
                        ></img>
                      </div>
                    </div>
                  </section>
                )}
                {this.state.benefitsScrollSection == 3 && (
                  <section id="sec3" className="benefits_grid">
                    <div
                      className="benefits_text_content benefits_grid_item_2 d-flex align-items-center"
                      style={{ marginRight: "0px", maxWidth: "27vw" }}
                    >
                      <div>
                        <div className="benefits_text_title">
                          Delivering reliablity using edge processing
                        </div>
                        <div className="benefits_text_sub_title">
                          Unlike conventional remote monitoring which polls
                          every fifteen minutes, inputs are polled or sampled by
                          the Rulo controller multiple times per second. This
                          ensures you receive reliable alarms and notifications
                          without adding to your data costs.
                        </div>
                        <div className="benefits_text_desc">
                          All alarms are fully remotely configurable by the
                          user.
                        </div>
                      </div>
                    </div>
                    <div className="benefits_grid_item_1">
                      <div className="benefits_section1_outerdiv">
                        <img
                          style={{ width: "100%" }}
                          src={require("../../assets/Benefits/benefits_section3_img.png")}
                        ></img>
                      </div>
                    </div>
                  </section>
                )}
                {this.state.benefitsScrollSection == 4 && (
                  <section id="sec4" className="benefits_grid">
                    <div className="benefits_text_content benefits_grid_item_2 d-flex align-items-center">
                      <div>
                        <div
                          className="benefits_text_title"
                          style={{ marginBottom: "1.5rem" }}
                        >
                          Never trust, always verify
                        </div>
                        <div
                          className="benefits_text_sub_title"
                          style={{ marginBottom: "1.5rem" }}
                        >
                          Using the device root-of-trust, transport layer
                          security (256-bit TLS 1.2), and a single point of
                          access using the web portal, Rulo uses
                          NIST-recommended authentication and encryption to give
                          you a secure way to manage your assets.
                        </div>
                        <div className="benefits_text_desc">
                          Many industrial equipment and legacy devices were not
                          designed to be connected to the internet. Let us help
                          you mitigate the risk of cloud connectivity. Security
                          made easy, by Rulo.
                        </div>
                      </div>
                    </div>
                    <div className="benefits_grid_item_1">
                      <div className="benefits_section1_outerdiv">
                        <img
                          style={{ width: "80%", paddingRight: "6rem" }}
                          src={require("../../assets/Benefits/benefits_section4_img.png")}
                        ></img>
                        {/* <img
                          className="benefits_section1_overlay_img"
                          src={
                            this.state.is_small_screen
                              ? ""
                              : require("../../assets/Benefits/benefits_section1_ipad_screen.png")
                          }
                        ></img> */}
                      </div>
                    </div>
                  </section>
                )}
              </div>
            )}
            {this.state.is_small_screen && (
              <div
                id="outerDiv"
                className="home_mobile_outer_div"
                style={{ overflow: "auto" }}
              >
                <section id="sec1" className="benefits_grid">
                  <div className="benefits_text_content benefits_grid_item_2 d-flex align-items-center">
                    <div>
                      <div className="benefits_text_title">
                        Custom <span>reports</span>
                      </div>
                      <div className="benefits_text_sub_title">
                        Detailed reports when you want them and easy-to-read
                        dashboards when you’re on the go.
                      </div>
                      <div className="benefits_text_desc">
                        Rulo’s fully integrated end to end solution let's you
                        create custom reports. Whether you want a dashboard all
                        your assets or compare data from multiple assets, the
                        reports give you full flexibility.
                      </div>
                    </div>
                  </div>
                  <div className="benefits_grid_item_1">
                    <div className="benefits_section1_outerdiv">
                      <img
                        style={{ width: "100%" }}
                        src={
                          this.state.is_small_screen
                            ? require("../../assets/Benefits/benefits_section1_phone_img.png")
                            : require("../../assets/Benefits/benefits_section1_bg.svg")
                                .default
                        }
                      ></img>
                      <img
                        className="benefits_section1_overlay_img"
                        src={
                          this.state.is_small_screen
                            ? ""
                            : require("../../assets/Benefits/benefits_section1_ipad_screen.png")
                        }
                      ></img>
                    </div>
                  </div>
                </section>
                <section id="sec2" className="benefits_grid_reverse">
                  <div className="d-flex justify-content-end">
                    <div className="benefits_text_content benefits_grid_item_2 benefits_section2_text_margins d-flex align-items-center">
                      <div>
                        <div className="benefits_text_title benefits_section2_title">
                          Flash storage and forward
                        </div>
                        <div className="benefits_text_sub_title">
                          We understand that cellular connectivity, like phone
                          calls, sometimes get dropped. Our platform was
                          designed with real-world connectivity in mind. All
                          data and alarm logs are stored on a flash drive until
                          network connectivity is available. So, you can get
                          your data. Consistently.
                        </div>
                        <div className="benefits_text_desc">
                          Poor network connectivity is not a unique problem to
                          you. Rulo has the solution.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="benefits_grid_item_1">
                    <div className="benefits_section2_outerdiv">
                      <img
                        className="benefits_section2_img"
                        src={require("../../assets/Benefits/benefits_section2_img.png")}
                      ></img>
                      <img
                        className="benefits_section2_overlay_img"
                        src={
                          require("../../assets/Benefits/benefits_section2_graph.svg")
                            .default
                        }
                      ></img>
                    </div>
                  </div>
                </section>
                <section id="sec3" className="benefits_grid">
                  <div className="benefits_text_content benefits_grid_item_2 d-flex align-items-center">
                    <div>
                      <div className="benefits_text_title">
                        Delivering reliablity using edge processing
                      </div>
                      <div className="benefits_text_sub_title">
                        Unlike conventional remote monitoring which polls every
                        fifteen minutes, inputs are polled or sampled by the
                        Rulo controller multiple times per second. This ensures
                        you receive reliable alarms and notifications without
                        adding to your data costs.
                      </div>
                      <div className="benefits_text_desc">
                        All alarms are fully remotely configurable by the user.
                      </div>
                    </div>
                  </div>
                  <div className="benefits_grid_item_1">
                    <div className="benefits_section1_outerdiv">
                      <img
                        style={{ width: "100%" }}
                        src={require("../../assets/Benefits/benefits_section3_img.png")}
                      ></img>
                    </div>
                  </div>
                </section>
                <section id="sec4" className="benefits_grid">
                  <div className="benefits_text_content benefits_grid_item_2 d-flex align-items-center">
                    <div>
                      <div className="benefits_text_title">
                        Never trust, always verify
                      </div>
                      <div className="benefits_text_sub_title">
                        Using the device root-of-trust, transport layer security
                        (256-bit TLS 1.2), and a single point of access using
                        the web portal, Rulo uses NIST-recommended
                        authentication and encryption to give you a secure way
                        to manage your assets.
                      </div>
                      <div className="benefits_text_desc">
                        Many industrial equipment and legacy devices were not
                        designed to be connected to the internet. Let us help
                        you mitigate the risk of cloud connectivity. Security
                        made easy, by Rulo.
                      </div>
                    </div>
                  </div>
                  <div className="benefits_grid_item_1">
                    <div className="benefits_section1_outerdiv">
                      <img
                        style={{ width: "100%" }}
                        src={require("../../assets/Benefits/benefits_section4_img.png")}
                      ></img>
                      {/* <img
                        className="benefits_section1_overlay_img"
                        src={
                          this.state.is_small_screen
                            ? ""
                            : require("../../assets/Benefits/benefits_section1_ipad_screen.png")
                        }
                      ></img> */}
                    </div>
                  </div>
                </section>
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}
export default Benefits;
