import React, { useEffect, useState } from "react";
import "./AdditionalLinks.css";
import getDataFromS3 from "../../service/services";
import { useLocation } from "react-router-dom";

const AdditionalLinks = () => {
  /* Used useLocation for getting the selected id from the footer */
  const { state } = useLocation();

  /* Added state variables for showing the side tabs on the screen */
  const [links, setActiveLink] = useState<any>([
    { name: "Privacy Policy", is_active: true, updated_date: "", file: "" },
    {
      name: "Terms and Conditions",
      is_active: false,
      updated_date: "",
      file: ""
    },
    { name: "Hardware Warranty", is_active: false, updated_date: "", file: "" }
  ]);
  const [contents, setContents] = useState("");

  /* changeLink function added to update the is_active flag of the selected side tab */
  const changeLink = (index: any) => {
    let links_copy = Object.assign([], links);
    links_copy[index].is_active = true;
    links_copy.forEach((element: any, index1: any) => {
      if (index1 != index) {
        element.is_active = false;
      }
    });
    setActiveLink(links_copy);
  };

  /* Reading the file contents by passing the file path */
  const getFileContents = (path: any) => {
    fetch(path)
      .then(function(res) {
        return res.text();
      })
      .then(function(data) {
        setContents(data);
      });
    return contents;
  };

  /* S3 call added in useEffect for getting the additional_links data and stored in the respective objects in the local array */
  useEffect(() => {
    getDataFromS3("additional_links/additional_links.json").then(
      (data: any) => {
        for (let linkData of data) {
          let links_copy = Object.assign([], links);
          links_copy.forEach((element: any) => {
            if (linkData.link_name === element.name) {
              element.updated_date = linkData.updated_date;
              element.file = linkData.file_location;
            }
          });
          setActiveLink(links_copy);
        }
      }
    );
  }, []);

  /* Logic added for showing the particular id's content when clicked on the footer */
  useEffect(() => {
    let element = document.getElementById(String(state));
    if (state === "link1") {
      changeLink(0);
    } else if (state === "link2") {
      changeLink(1);
    }
    let offsetTop = element?.getBoundingClientRect();
    window.scrollTo({
      top: Number(offsetTop?.top),
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    document.title = "Legal";
  }, []);

  return (
    <div>
      {/* Banner section for mobile */}
      <section className="section_mobile">
        <div className="link_left_section ">
          <div className=" left_section_links mobile_banner">
            {/* Section for showing date on banner */}
            <div className="d-flex align-items-center">
              <img
                style={{ width: "1rem", marginRight: "1.3em" }}
                src={require("../../assets/rulo_matrix.svg").default}
              ></img>
              {links.map((link: any, index: number) => {
                return link.is_active ? (
                  <label className="mobile_banner_txt_heading mobile_banner_date_font">
                    {link.updated_date}
                  </label>
                ) : (
                  ""
                );
              })}
            </div>

            {/* Section for showing the selected link name on banner */}
            <div className="pt_2_4375">
              {links.map((link: any, index: number) => {
                return link.is_active ? (
                  <label className="mobile_banner_txt_heading">
                    {link.name}
                  </label>
                ) : (
                  ""
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/* Main section for the background */}
      <section style={{ background: "#FFFFFF" }}>
        {/* For giving the top and bottom padding */}
        <div className="links_flex_row">
          {/* Left tabs section */}
          <div className="link_left_section ">
            <div className=" left_section_links">
              <div className="d-flex align-items-center">
                <img
                  style={{ width: "1rem", marginRight: "1.3em" }}
                  src={require("../../assets/rulo_matrix.svg").default}
                ></img>
                <label className="links_left_sec_header">
                  Additional Links
                </label>
              </div>
              <div className="links_left_section_second_row">
                {links.map((link: any, index: number) => {
                  return (
                    <div
                      className={
                        link.is_active == true ? "selected_section_links" : ""
                      }
                      onClick={() => {
                        changeLink(index);
                      }}
                    >
                      <div className="left_section_sub_text_links">
                        {link.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* Right contents section */}
          <div className="links_parent">
            {/* Privacy policy section */}
            {links[0].is_active && (
              <section id="link1">
                <div className="heading_align">
                  <div className="link_heading">Privacy Policy</div>
                  <div className="link_date">{links[0].updated_date}</div>
                </div>
                <div
                  className="links_text"
                  dangerouslySetInnerHTML={{
                    __html: getFileContents(links[0].file)
                  }}
                ></div>
              </section>
            )}
            {/* Terms of Use/Terms and Conditions section */}
            {links[1].is_active && (
              <section id="link2">
                <div className="heading_align">
                  <div className="link_heading">Terms and Conditions</div>
                  <div className="link_date">{links[1].updated_date}</div>
                </div>
                <div
                  className="links_text"
                  dangerouslySetInnerHTML={{
                    __html: getFileContents(links[1].file)
                  }}
                ></div>
              </section>
            )}
            {/* Hardware warranty section */}
            {links[2].is_active && (
              <section id="link3">
                <div className="heading_align">
                  <div className="link_heading">Hardware Warranty</div>
                  <div className="link_date">{links[2].updated_date}</div>
                </div>
                <div
                  className="links_text"
                  dangerouslySetInnerHTML={{
                    __html: getFileContents(links[2].file)
                  }}
                ></div>
              </section>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
export default AdditionalLinks;
