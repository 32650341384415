import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./Blogpost.css";
import getDataFromS3 from "../../service/services";

const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname: string) => navigate(`${pathname}`);
};
const Blogpost = () => {
  const { state } = useLocation();
  let { id } = useParams();
  const [blogData, setBlogData] = useState<any>({ content: [], image: "" });
  useEffect(() => {
    setBlogData((preVdata: any) => {
      return {
        ...preVdata,
        date_time: (state as any).date_time,
        heading: (state as any).heading,
        sub_heading: (state as any).sub_heading,
        image: (state as any).image,
      };
    });
    fetch((state as any).content).then(async (res: any) => {
      let blogContent = await res.text();
      setBlogData((preVdata: any) => {
        return { ...preVdata, content: blogContent };
      });
    });
  }, []);
  const navigateSearch = useNavigateSearch();
  const navTo = (path: string) => {
    window.scrollTo(0, 0);
    navigateSearch(path);
  };
  return (
    <>
      <div className="blogpost_flex_row">
        <div className="blogpost_left_section ">
          <div className=" left_section_blogpost">
            <div className="d-flex align-items-center">
              <img
                style={{ width: "1.25rem", marginRight: "1.25rem" }}
                src={require("../../assets/rulo_matrix.svg").default}
              ></img>

              <label className="blogpost_left_sec_header">
                {blogData.date_time}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="blogpost_title">
        <label> {blogData.heading}</label>
      </div>
      <div className="blogpost_content_container">
        <div className="blogpost_content_grid">
          <div className="blogpost_grid_item2">
            <div className="blogpost_content_sub_heading">
              {blogData.sub_heading}
            </div>
            {/* {
                        (blogData.content as any[]).map((item:any,index:any)=>{
                            return(
                                <div className="blogpost_content_para" key={index}>{item}</div>
                            )
                        })
                    } */}
            <div className="blogpost_content_para" dangerouslySetInnerHTML={{ __html: blogData.content }}></div>
          </div>
          <div className="blogpost_grid_item1">
            <img src={blogData.image}></img>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Blogpost;
