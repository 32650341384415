import React, { useState, useCallback, useEffect } from "react";
import "./Blog.css";
import getDataFromS3 from "../../service/services";
import { useNavigate } from "react-router-dom";
let currentVisibleBlogData: any[] = [];
let currentScreen: boolean = true;
let viewMoreClickCount: number = 1;
let fullData: any[] = [];
const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname: string, data: any) =>
    navigate(`${pathname}`, { state: data });
};
const Blog = () => {
  const [blogData, setData1] = useState<any[]>([]);
  const [visibleBlogData, setVisibleBlogData] = useState<any[]>([]);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(true);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(true);
  const navigateSearch = useNavigateSearch();
  const navToWithData = (path: string, data: any) => {
    window.scrollTo(0, 0);
    navigateSearch(path, data);
  };

  useEffect(() => {
    document.title = "Blog";
  }, []);

  function resizeHandler() {
    if (!currentScreen && window.innerWidth <= 850) {
      setIsSmallScreen(true);
      currentScreen = true;
      viewMoreClickCount = 1;
      initalDataLoad();
    } else if (currentScreen && window.innerWidth > 850) {
      setIsSmallScreen(false);
      currentScreen = false;
      viewMoreClickCount = 1;
      initalDataLoad();
    }
  }
  function initalDataLoad() {
    let itemCount = currentScreen ? 3 : 9;
    setVisibleBlogData(fullData.slice(0, itemCount));
    currentVisibleBlogData = fullData.slice(0, itemCount);
    setShowLoadMore(itemCount <= fullData.length);
  }
  function loadMore() {
    viewMoreClickCount++;
    let itemCount = (currentScreen ? 3 : 9) * viewMoreClickCount;
    setVisibleBlogData(fullData.slice(0, itemCount));
    setShowLoadMore(itemCount <= fullData.length);
  }
  useEffect(() => {
    for (let i = 0; i < 100; i++) {
      window.clearInterval(i);
    }
    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    getDataFromS3("blog/blog.json").then((data: any[]) => {
      let data_copy = data;
      let date_array: any[] = [];
      data_copy.forEach((item: any) => [
        date_array.push(Number(new Date(item.date_time)))
      ]);
      data_copy.sort(sortFn("date_time", "dsc"));
      setData1(data_copy);
      fullData = data_copy;
      initalDataLoad();
    });
    function sortFn(attr: any, order: any) {
      return (a: any, b: any) => {
        let start = new Date(a[attr]);
        let end = new Date(b[attr]);
        return sortByValue(start, end, order);
      };
    }
    function sortByValue(start: any, end: any, order: any): number {
      if (start > end) {
        return order == "asc" ? 1 : -1;
      } else if (start < end) {
        return order == "asc" ? -1 : 1;
      } else {
        return 0;
      }
    }
  }, []);
  return (
    <div>
      <section style={{ background: "#253746" }}>
        <div className="blog_flex_row no_padding">
          <div className="blog_left_section ">
            <div className=" left_section_blog blog_padding_top_5rem">
              <div className="d-flex align-items-center">
                <img
                  style={{ width: "1rem", marginRight: "1.3em" }}
                  src={require("../../assets/rulo_matrix.svg").default}
                ></img>
                <label className="blog_left_sec_header color-white">
                  Rulo blog
                </label>
              </div>
            </div>
          </div>
          <div className="blog_right_side  blog_banner_right">
            <div style={{ position: "relative" }}>
              <div className="blog_banner_right_side"></div>
              <div className="blog_banner_text_box">
                <div className="blog_banner_title">{blogData[0]?.heading}</div>
                <div className="blog_banner_date">{blogData[0]?.date_time}</div>
                <button
                  className="orange-button blog-banner-button-pd"
                  onClick={() => {
                    navToWithData(`${blogData[0].id}`, blogData[0]);
                  }}
                >
                  <div
                    className="flex_row justify-content-center"
                    style={{ gap: "0.5em" }}
                  >
                    <div>
                      <img
                        style={{ width: "1rem" }}
                        src={require("../../assets/button_arrow.svg").default}
                      ></img>
                    </div>
                    <div style={{ height: "fit-content" }}>Read Article</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog_grid_section">
        <div className="blog_grid_container">
          {visibleBlogData.map((item: any, index: any) => {
            return (
              <div
                key={item.date_time}
                className="blog_grid_item"
                onClick={() => {
                  navToWithData(`${item.id}`, item);
                }}
              >
                <div className="blog_grid_date">{item.date_time}</div>
                <div className="blog_grid_heading">{item.heading}</div>
                <div className="blog_grid_sub_heading">{item.sub_heading}</div>
              </div>
            );
          })}
        </div>

        {showLoadMore && (
          <div className="d-flex justify-content-center">
            <div
              className="blog_load_more_button "
              onClick={() => {
                loadMore();
              }}
            >
              Load More
            </div>
          </div>
        )}
      </section>
    </div>
  );
};
export default Blog;
